.esign-container {
  display: flex;
  align-items: center;
  max-width: 1050px;
  margin: 0 auto;
  min-height: 600px;
  height: 100%;
  flex-flow: column;
  margin-top: 30px;
  label,
  input,
  select {
    font-size: 16px;
  }
  h1,
  label {
    font-family: "'LatoBold'";
    color: $black-base;
    text-align: center;
  }
  h4,
  h5 {
    color: $black-base;
    font-family: 'LatoRegular';
  }
  .receipt-display-container {
    width: 80%;
  }
  .esign-input-fields {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 48px;
    margin-bottom: 48px;
    .sign-and-submit-container {
      margin-top: 24px;
    }
    .sign-field {
      .signature {
        display: flex;
        flex-direction: column;
        width: 45%;
        align-items: flex-start;
        margin-bottom: 24px;
        .pointer {
          cursor: pointer;
        }
        .text-container {
          border: none;
          border-bottom: 1px solid $black-base;
          font-style: italic;
          height: 48px;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
          .edit-signature-icon {
            margin-bottom: 10px;
          }
          .signature-text {
            font-style: italic;
          }
          .placeholder-text {
            font-size: 16px;
            opacity: 0.5;
          }
        }
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .ssn-input-container {
        width: 45%;
        input {
          border: none;
          border-bottom: 1px solid $black-base;
        }
      }
      .procedure-date-input-container {
        width: 45%;
      }
    }
  }
}
@media (max-width: 500px) {
  .esign-container {
    .receipt-display-container {
      width: 100%;
    }
  }
  .esign-input-fields {
    width: 90% !important;
  }
}
