.wizard-header {
  text-align: center;
  height: 80px;
  border-bottom: 2px solid $lightgrey-base;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .wizard-logo {
    height: 25px;
    width: auto;
  }

  .wizard-header-title {
    color: $black-base;
    font-family: "'LatoBold'";
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
  }
}
.wizard-header-title {
  color: $black-base;
  font-family: "'LatoBold'";
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
}
