.announcement-banner {
  margin-top: 16px;
  text-align: center;
  border: 1px solid $red-base;
  background-color: $red-light;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  padding: 18px;
  border-radius: 6px;
  max-width: 1500px;

  @media (max-width: 1200px) {
    flex-direction: column;
    row-gap: 40px;
  }

  button {
    max-width: 500px;
  }

  .tooltip-icon {
    width: 25px;
    height: 25px;
    margin: 4px 25px 4px 10px;
    filter: invert(41%) sepia(65%) saturate(5396%) hue-rotate(333deg)
      brightness(74%) contrast(105%);
  }
  .banner-note {
    font-size: 14px;
    padding: 5px 0;
    margin: 10px 0;
  }
  .announcement-description {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;

    .no-signers-banner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      p {
        padding: 0 0 18px 0;
        font-family: 'LatoRegular';
        color: $black-base;
        line-height: 150%;
      }
      span {
        font-weight: bold;
      }
      button {
        margin: 0;
        width: fit-content;
      }
    }
    .non-signer-banner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      h1 {
        font-family: "'LatoBold'";
        color: $black-base;
      }
      p {
        padding: 5px 0 5px 0;
        font-family: 'LatoRegular';
        color: $black-base;
        button {
          border: none;
          background-color: transparent;
          font-family: 'LatoRegular';
          font-weight: bold;
          color: $blue-base;
          font-size: 16px;
          width: fit-content;
          padding: 0;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .signer-emails {
        display: flex;
        flex-wrap: wrap;
        span {
          margin: 0 8px 0 0;
        }
      }
      span {
        font-family: 'LatoRegular';
        color: $black-base;
        font-size: 16px;
        text-align: center;
        padding: 6px 0 6px 0;
      }
      a {
        font-family: 'LatoRegular';
        color: $blue-base;
        font-size: 16px;
      }
      button {
        padding: 16px;
        margin: 8px 0 8px 0;
        width: 20%;
      }
    }
  }
  .route-to-new-deal {
    width: 100%;
    margin-left: 32px;
  }
  p {
    font-family: "'LatoBold'";
    text-align: left;
  }
}
