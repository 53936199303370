.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-page-container {
  width: 80%;
  height: 50vh;
  background: $grey-light;

  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .error-page-title {
    padding: 2% 0;
    color: rgba($black-base, 0.4);
  }
  .error-page-paragraph {
    color: rgba($black-base, 0.3);
  }
}
.expiration-error-container {
  display: flex;
  justify-content: center;

  .date-sync {
    width: 90%;
    font-size: 20px;
    line-height: 1.5em;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .error-card-container {
    width: 70%;
    margin: 0 auto;
    background: $darkgrey-lighter;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .error-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $darkgrey-lighter;
    padding: 20px;
    h1 {
      font-size: 32px;
      text-align: center;
      width: 80%;
      margin: 0 auto;
      margin: 2%;
    }
    h2 {
      font-size: 26px;
      letter-spacing: 2px;
    }
    h3 {
      font-size: 20px;
    }
    .second-header {
      margin: 5% 0 2% 0;
    }
  }
}
