.account-verification {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;

  h1 {
    font-family: "'LatoBold'";
    color: $black-base;
  }

  h4 {
    font-family: 'LatoRegular' !important;
    margin-left: 15%;
    margin-right: 15%;
    text-align: center;
  }

  .verification-sub-title {
    margin-top: 3%;
    justify-content: center;

    button {
      background: none;
      font-family: 'OpenSans' !important;
      font-weight: bold;
      font-size: 16px;
      border: none;
      padding: 0;
      color: $blue-base;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .verification-card {
    border: 2px solid $darkgrey-lighter;
    padding: 20px;
    width: 80%;
    margin-top: 20px;
  }

  .card-half {
    width: 50%;
    float: left;
  }

  a {
    font-size: inherit;
    color: $blue-base;
    font-family: inherit;
  }
}
