$message-font-size: 16px;
.verify-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  min-height: 600px;
  height: calc(100vh - 200px);
  flex-direction: column;
  h2,
  h5 {
    font-family: "'LatoBold'";
    color: $black-base;
    text-align: center;
  }
  h4 {
    font-family: 'LatoRegular';
    color: $black-base;
  }
  h5 {
    padding-bottom: $message-font-size;
  }

  .verify-ssn-input,
  .verify-submit,
  .verify-consent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    margin: 15px 0 15px 0;
  }
  .verify-ssn-input {
    flex-wrap: wrap;
  }
  .verify-instructions {
    width: 80%;
    margin: 15px 0 15px 0;
    span {
      font-family: "'LatoBold'";
      font-weight: bold;
    }
  }
  .input-and-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 15px;
    .verify-message {
      margin-top: 10px;
      min-height: $message-font-size;
      color: $red-base;
    }
  }
  label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'LatoRegular';
    color: $black-base;
    font-size: $base-font-size * 1.25;
    line-height: 1.5;
    input {
      margin-top: 5px;
      min-width: 23px;
    }
  }

  .verify-ssn-input {
    display: flex;
    flex-direction: row;
    font-family: "'LatoBold'";
  }
  .agree-link {
    font-family: 'LatoItalic';
    font-size: $base-font-size * 1.25;
    color: $blue-base;
    font-style: italic;

    &:hover {
      color: $blue-base;
    }

    &:active {
      color: $blue-base;
    }
  }
}
@media (max-width: 420px) {
  .verify-container {
    height: calc(100vh + 200px);
  }
}
