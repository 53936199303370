.page-header {
  display: flex;
  justify-content: center;
  .logo {
    height: 50px;
    width: auto;
  }
}

.select-for-print-wrap {
  flex: none;
}
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm 5mm 10mm 5mm;
}
