.home-content {
  max-width: 1050px;
  margin: 0 auto;
  height: calc(100vh - 200px);
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  overflow: auto;

  .home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .home-left {
      text-align: center;
      width: 50%;
      .MuiInput-root {
        width: 370px;
      }
      a {
        font-style: italic;
        font-weight: inherit;
      }
    }

    .home-right {
      text-align: center;
      width: 50%;
      padding: 20px 50px;
    }

    .a-container {
      margin-top: 40px;
      .app-started {
        color: $red-base;
        font-family: 'LatoItalic';
        font-size: 16px;
      }
    }

    .search-practice {
      font-family: 'LatoRegular';
      font-size: 12px;
    }

    .separator {
      position: relative;
      border: 1px solid $grey-base;
      height: 250px;

      .or {
        position: absolute;
        top: 50%;
        left: -22px;
        background-color: $white-base;
        padding: 10px;
        font-size: 16px;
        color: $grey-base;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .home-content {
    align-items: flex-start;
    .home-container {
      flex-direction: column;
      .home-left,
      .home-right {
        width: 100%;
      }
      .separator {
        width: 80%;
        height: unset;
        margin-top: 40px;
        margin-bottom: 40px;
        .or {
          left: 40%;
          top: -15px;
        }
      }
      .w-70 {
        width: 80%;
      }
      .practice {
        font-size: 24px;
      }
    }
  }
}
