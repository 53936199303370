@font-face {
  font-family: 'LatoBlack';
  font-weight: 900;
  src: url('../fonts/Lato/Lato-Black.ttf');
}

@font-face {
  font-family: 'LatoRegular';
  font-weight: 400;
  src: url('../fonts/Lato/Lato-Regular.ttf');
}

@font-face {
  font-family: "'LatoBold'";
  font-weight: 700;
  src: url('../fonts/Lato/Lato-Bold.ttf');
}

@font-face {
  font-family: 'LatoItalic';
  font-style: italic;
  src: url('../fonts/Lato/Lato-Italic.ttf');
}

@font-face {
  font-family: 'LatoLightItalic';
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/Lato/Lato-LightItalic.ttf');
}

@font-face {
  font-family: 'LatoLight';
  font-weight: 300;
  src: url('../fonts/Lato/Lato-Light.ttf');
}

@font-face {
  font-family: 'LatoThin';
  font-weight: 100;
  src: url('../fonts/Lato/Lato-Thin.ttf');
}

@font-face {
  font-family: 'LatoThinItalic';
  font-weight: 100;
  font-style: italic;
  src: url('../fonts/Lato/Lato-ThinItalic.ttf');
}
