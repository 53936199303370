.new-deal-container {
  width: 100%;
  min-height: fit-content;
  position: absolute;
  background-color: $white-base;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  .new-deal-main {
    padding-top: 86px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .new-deal-header {
    position: fixed;
    border-bottom: 1px solid $grey-base;
    width: 100%;
    padding: 10px 8% 10px 8%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $white-base;
    z-index: 1;
    img {
      width: 150px;
      height: auto;
    }
    .close-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $grey-base;
      border-radius: 5px;
      .close-button {
        padding: 5px;
        cursor: pointer;
      }
    }
  }
}
