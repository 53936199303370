footer {
  background-color: $lightgrey-base;
  height: 100px;
  padding: 20px;

  .footer-container {
    display: flex;
    justify-content: center;
    gap: 50px;
    a {
      text-decoration: none;
      color: $darkgrey-base;
      font-size: 14px;
      font-family: 'LatoRegular';
      font-weight: 500;
      cursor: pointer;
      margin-bottom: 20px;
    }
  }

  div.copyright {
    font-size: 12px;
    color: $darkgrey-base;
    font-family: 'LatoRegular';
    text-align: center;
  }
}

.merchant-portal {
  footer {
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background: $lightgrey-base;

    li {
      list-style-type: none;
    }
    ul {
      padding: unset;
    }
  }
  .footer-container {
    display: flex;
    width: 85%;
    margin: 0 auto;
    margin-top: 54px;
    justify-content: center;
    &__content {
      width: 30%;
      &--title {
        font-weight: bold;
        font-family: "'LatoBold'";
        color: $black-base;
        letter-spacing: 0.54px;
        opacity: 0.7;
      }
      &--links {
        margin-top: 20px;
      }
    }
    &__logo {
      margin-right: 20px;
    }
    .disabled-link {
      color: $darkgrey-base;
    }
  }
  @media (max-width: 768px) {
    .footer-container {
      display: none;
      &__content {
        width: 100%;
      }
    }
  }
  .simplified-footer-container {
    display: flex;
    margin: 0 auto;
    margin-top: 54px;
    width: 85%;
  }
  .seperator {
    margin: 35px 0px 15px 0px;
    width: 86%;
    border: 0.8px solid $black-light;
  }
  .footer-container__logo {
    img {
      height: 62px;
      width: 135px;
    }
  }

  .footer {
    &__footer {
      width: 86%;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
    }
  }
}
