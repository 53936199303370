​path {
  fill: $white-base;
}

nav.marketing-toolbar {
  width: 700px;
  height: 170px;
  display: flex;
  justify-content: space-around;
  background-color: $white-base;
  border-radius: 0;
  text-align: center;
  color: $black-base;
  margin: 50px auto 0;

  .icon-link {
    color: $black-base;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: self-start;
    height: 100%;
    padding: 20px 10px 20px 20px;
    text-align: left;
    line-height: 100%;
  }

  div.toolbar-icon {
    border: 1px solid $lightgrey-light;
    border-radius: 4px;
    width: 200px;
    height: 167px;
    text-align: center;

    a {
      background: $lightgrey-light;
    }
    a.active {
      background: $white-base;
    }

    h3 {
      font-size: 16px;
      font-weight: 700;
      padding: 0;
      margin: 0 0 8px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: darkgray;
      line-height: 130%;
    }

    img {
      fill: $white-base;
      stroke: $red-base;
      background: rgba($red-base, 0.1);
      height: 28px;
      width: 28px;
      text-align: center;
      padding: 2px;
      border-radius: 2px;
    }
  }

  .MuiSvgIcon-root {
    fill: $red-base;
    stroke: $white-base;
  }
}

.qr-code-customizer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .qr-code-customizer-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-family: "'LatoBold'";
    color: $black-base;
  }

  .custom-application-link {
    text-align: center;
    margin-top: 20px;
    font-family: "'LatoBold'";
    color: $black-base;

    a {
      color: $blue-base;
      font-size: 16px;
    }
  }

  .qr-code-column {
    margin-top: 10px;
  }
  .qr-code-result {
    width: 100vw;
    max-width: 680px;
    height: auto;
    text-align: center;
    img {
      width: 100%;
    }
    .qr-img-container {
      min-height: 520px;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .info {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    text-align: center;
    margin-top: 0px;
    font-size: clamp(12px, 1.4vw, 14px);
  }

  .qr-code-selection {
    padding: 15px;

    .selector {
      margin: 10px;
    }

    .qr-code-selection-option {
      margin: 20px;
    }

    fieldset {
      margin-bottom: 0px !important;
    }
  }

  &__content {
    width: 100%;
  }
}
