.test-environment-banner {
  width: 100%;
  background-color: $lightblue-base;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black-base;
  padding: 7px;
  display: none;
  font-size: 12px;
  @media (max-width: 1201px) {
    display: flex;
    p {
      font-size: 12px;
    }
  }
  @media (max-width: 575.98px) {
    p {
      display: none;
    }
  }
}
.sandbox-mode-title {
  border: 2px solid $blue-base;
  display: flex;
  align-items: center;
  padding: 7px;
  margin-right: 5px;
  font-weight: bold;
}

/**
Adapted from 
https://codepen.io/nxworld/pen/oLdoWb
**/

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: fixed;
  z-index: 10000000;

  @media (max-width: 1200px) {
    display: none;
  }
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid $lightblue-base;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: $lightblue-base;
  box-shadow: 0 5px 10px rgba($black-base, 0.1);
  color: $black-base;
  font: 700 14px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba($black-base, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 40px;
  transform: rotate(45deg);
}
