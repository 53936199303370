.esign-success-container,
.esign-error-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  min-height: 600px;
  flex-flow: column;

  h2 {
    font-family: "'LatoBold'";
    color: $black-base;
    text-align: center;
    font-size: 24px;
  }
  h4 {
    margin-top: 16px;
    font-size: 16px;
    font-family: 'LatoRegular';
    color: $black-base;
    line-height: 2;

    a {
      color: $black-base;
      font-size: 16px;
      font-family: 'LatoRegular';
    }

    .bold {
      font-family: 'LatoBold';
    }
  }
  .header-message {
    text-align: center;
    width: 80%;

    .large-icon-center {
      display: inline-block;
      padding-bottom: 15px;
      margin-right: auto;
      width: 60px;
    }
  }

  .promo-tips-table {
    width: 80%;
    -moz-border-radius: 0px;
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: $lightblue-base;
    .promo-tips-header-row {
      -moz-border-radius: 0px;
      -webkit-border-radius: 10px 10px 0px 0px;
      border-radius: 10px 10px 0px 0px;
      padding: 2px 20px;
      background-color: $blue-base;
      color: $white-base !important;

      p {
        color: $white-base !important;
      }
      h4 {
        color: $white-base !important;
      }
    }
    .promo-tips-row {
      padding: 10px 20px;
    }
  }
}
