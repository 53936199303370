:root {
  --toastify-toast-top: 110px !important;
}
.toast-base {
  font-family: "'LatoRegular'", 'Open Sans', sans-serif;
  font-weight: 700;
  &.toast-error {
    background-color: $red-light !important;
    color: $red-base !important;
    border-left: 10px solid $red-base !important;
  }
  &.toast-success {
    background-color: $green-light !important;
    color: $green-base !important;
    border-left: 10px solid $green-base !important;
  }
}

.Toastify__toast-container {
  width: 750px !important;
  max-width: 100vw;
}
