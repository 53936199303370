.sales-confirmation {
  &__container {
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 20px;
    flex-direction: column;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      border-bottom: 3px solid $lightgrey-base;
      text-transform: uppercase;
    }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 20px;
      .last-item {
        border-bottom: 1px solid $lightgrey-base;
      }

      .line-item-name {
        padding-left: 20px;
      }

      .line-item,
      .transaction-total {
        background-color: $white-base;
      }

      .detail-header {
        width: 225px;
      }

      &--row {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10%;
      }
    }
    table {
      width: 100%;

      td {
        text-align: left;
        font-size: 16px;
        line-height: 1.5;
      }
    }
    button {
      margin: 0 auto;
      width: 100%;
    }
  }
  .modal .modal-inner {
    width: 100% !important;
    padding: unset !important;
  }
}

.loan-adjustment-actions {
  display: flex;
  justify-content: space-evenly;
}
