.calculator {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.calculator-form-container {
  width: 100%;
  min-width: 500px;
  display: flex;
  justify-content: flex-start;
  margin-top: 10%;
}

.calculator-form-submit {
  width: 100%;

  button {
    width: 100%;
  }
}

.promotional-plans-container,
.calculator-form {
  width: 100%;
}

.calculator-modal-content {
  border-top: 30px solid $red-base;
  border-bottom: 30px solid transparent;
  min-width: 350px;
  min-height: 300px;
  max-width: 40% !important;
}
.calculator-form {
  form {
    width: 80%;
    margin: 0px auto;
  }
  fieldset {
    margin-bottom: 2rem !important;
  }
  .align-center {
    text-align: center;
  }
  .radioInput {
    display: flex;
    width: 300px;
    justify-content: space-around;
  }
  .radioInput label {
    display: flex;
    align-items: center;
  }

  .button-primary {
    margin-top: 20px;
  }

  .title {
    width: 80%;
    margin: 0 auto;
  }
}
.calculator-print-to-pdf-form {
  display: flex;
  flex-direction: column;
  align-items: space-around;

  .title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 30px;
  }
  .row {
    padding-left: 10%;
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: flex-start;
  }
  input {
    width: 80%;
  }
  h4 {
    color: $black-base;
    font-family: "'LatoBold'";
  }
}
