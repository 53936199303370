.missing-id-expiration-modal {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  h1 {
    text-align: center;
  }
  h2 {
    text-align: center;
    padding: 14px 0 14px 0;
  }
  label {
    font-size: 16px;
  }
  input:disabled {
    border: 0;
  }
  input {
    border-bottom: 1px solid #00000e;
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 16px;
    font-family: 'LatoRegular';
    padding: 0.5rem 0;
    &.invalid {
      border-bottom: 1px solid #bf2536;
      background-color: var(--light-red);
    }
  }
  input:focus {
    background: var(--light-blue);
  }

  .input-container-single {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 12px;
    font-size: 16px;

    label {
      padding: 16px 0 0 0;
      font-family: "'LatoBold'";
      font-size: 16px;
      color: #00000e;
      &.invalid {
        color: $red-base;
      }
      border: 0;
    }
    span.error {
      color: $lightblue-base;
      font-family: 'LatoRegular';
      width: 100%;
      min-height: 16px;
    }
  }
}
