.practice-search-input {
  width: 100%;
  input {
    border: unset;
    border-bottom: 1px solid $black-base;
    &::placeholder {
      padding-left: 2px;
      font-size: 12px;
      font-style: normal;
      letter-spacing: 0px;
      color: $darkgrey-base;
    }
  }
  .spinner {
    height: 20px;
  }
}
.w-70 {
  width: 70%;
}
@media (max-width: 575.98px) {
  .practice-search-input {
    .spinner {
      height: 10px;
    }
  }
}
