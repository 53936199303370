.action-result-container {
  height: calc(100vh - 200px);
  font-family: "'LatoBold'";
  max-width: 1050px;
  margin: 0 auto;
  padding-top: 48px;
  width: 65%;

  header {
    padding-bottom: 2rem;

    h1 {
      font-family: 'LatoBlack';
      font-size: 1.5rem;
    }
  }

  p {
    color: $black-base;
    font-family: inherit;
  }

  a {
    color: $blue-base;
    font-family: inherit;
    font-size: 1rem;
    font-style: italic;
  }

  .button-primary {
    width: 50%;
  }

  .next-step {
    margin-top: 2rem;
  }

  .result-table {
    border: 1px solid $darkgrey-lighter;
    margin-bottom: 30px;
    margin-top: 30px;

    th {
      background: $darkgrey-lighter;
      color: $black-base;
      font-size: 13px;
      letter-spacing: 1px;
    }
    thead {
      border-bottom: unset;
    }
    td {
      font-family: 'LatoRegular';
      margin: unset;
    }

    .action {
      display: flex;
      font-family: inherit;
      gap: 20px;

      button {
        font-family: 'LatoRegular';
        letter-spacing: 1.4px;
      }
    }
  }
}
