.app-modal-header {
  border-bottom: 1px solid $grey-base;
  display: flex;
  align-items: center;
  padding: 20px 10vw;
}

.app-modal-header img {
  width: 120px;
}

.app-modal-header button {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white-base;
  border: 1px solid $grey-base;
  transition: border-radius 0.25s ease-in, transform 0.25s;
  transform: rotate(0);

  color: $darkgrey-base;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

.app-modal-header button:hover {
  transition: border-radius 0.25s ease-in-out, transform 0.5s;
  border-radius: 44px;
  transform: rotate(180deg);

  svg {
    color: $grey-base;
  }
}

.modal-list {
  padding: 20px 40px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
}

.modal-list-item {
  margin: 10px 0;
  font-weight: lighter;
}

.modal-list-item a {
  font-size: inherit;
  font-weight: lighter;
  text-decoration: none;
}
.modal-list-item a:hover {
  text-decoration: underline;
}
