@use 'sass:color';

.credit-portal {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 64px 32px;

    .logo {
      width: 14rem;
    }

    @media (max-width: 575.98px) {
      padding: 44px 32px;
    }
  }
}

.merchant-portal {
  .header {
    padding: 0px;
    width: 100%;
    background: $white-base;
    position: relative;
    top: 0px;
    z-index: 999;

    .header_logo {
      width: 112px;
      text-align: center;
    }

    .logo {
      width: 7rem;
      // transition: width 0.5s ease-out;
    }
  }
  .header.nav-height-small {
    box-shadow: 0 1px 2px rgba($black-base, 0.15);

    .logo {
      width: 7rem;
      // transition: width 0.5s ease-in-out;
    }
  }

  .header-login {
    background: $black-base;
    padding: 8px 96px;
    position: relative;
    top: 0;
    z-index: 1000;

    @media (max-width: 1200px) {
      padding: 8px 12px;
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 74vw;
      margin: 0 auto;
      color: $white-base;
      letter-spacing: 0.3px;
      font-family: 'LatoRegular';
      font-size: 14px;

      @media (max-width: 1200px) {
        max-width: 96vw;
        font-size: 12px;
      }

      .navDropdown:hover {
        background-color: $white-base;
        color: $black-base;
      }

      .dropdown-content {
        display: none;
        position: absolute;
        background-color: $lightgrey-light;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba($black-base, 0.2);
        z-index: 9999;
      }

      .dropdown-content a {
        float: none;
        color: $black-base;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
        font-family: inherit;
      }

      .dropdown-content .disabled-link {
        float: none;
        display: block;
        text-align: left;
        font-family: inherit;
        font-style: normal;
        padding: 12px 16px;
        opacity: 0.5;
      }

      /* Add a grey background color to dropdown links on hover */
      .dropdown-content a:hover {
        background-color: $darkgrey-lighter;
      }

      /* Show the dropdown menu on hover */
      .navDropdown:hover .dropdown-content {
        display: block;
      }
    }
  }

  .header__content {
    display: flex;
    align-items: center;
    gap: 142px;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    transition: padding 0.5s ease-in-out;
    overflow: hidden;

    @media (max-width: 1200px) {
      gap: 22px;
    }

    .navlink a,
    button.link-primary {
      color: $black-base;
      font-family: 'LatoRegular';
      font-size: calc(14 / 16 * 1rem);
      text-transform: uppercase;
      text-decoration: none;
      -webkit-tap-highlight-color: transparent;
    }
    ul.nav {
      column-gap: 0;
    }
    .navlink a {
      min-width: 120px;
      display: block;
      text-align: center;
      position: relative;

      @media (max-width: 1200px) {
        padding: 30px 10px;
        font-size: 11px;
        min-width: 50px;
      }
    }
    li.navlink.active a {
      color: $black-base;
      padding-top: 30px;
    }
    li.navlink.active a::before {
      display: inline-block;
      content: '';
      opacity: 1;
      width: 100%;
      height: 10px;
      background: $red-base;
      position: absolute;
      top: 0px;
      left: 0;
    }

    .navlink a:hover {
      cursor: pointer;
      background: $white-base;
    }

    .navlink a::before {
      display: inline-block;
      transition: height 0.1s ease-in-out;
      opacity: 1;
      content: '';
      width: 100%;
      height: 0px;
      background: $red-base;
      position: absolute;
      top: 0px;
      left: 0;
    }

    .navlink a:hover::before {
      opacity: 1;
      content: '';
      width: 100%;
      height: 10px;

      background: $red-base;
      position: absolute;
      top: 0px;
      left: 0;
    }

    .navlink a:not(:hover) {
      // transition: opacity 0.4s ease-in-out;

      // transition: opacity 0.4s ease-in-out;
      // transition: box-shadow 0.1s ease-in-out, padding 0.5s ease-in-out;
    }

    .disabled-link {
      font-size: 14px !important;
    }

    .dropdown .dropbtn {
      font-size: 16px;
      border: none;
      outline: none;
      color: $white-base;
      padding: 14px 16px;
      background-color: inherit;
      font-family: inherit;
      /* Important for vertical align on mobile phones */
      margin: 0;
      /* Important for vertical align on mobile phones */
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: $lightgrey-light;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba($black-base, 0.2);
      z-index: 9999;
    }

    .dropdown-content a {
      float: none;
      color: $black-base;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      text-align: left;
    }

    /* Add a grey background color to dropdown links on hover */
    .dropdown-content a:hover {
      background-color: $darkgrey-lighter;
    }

    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {
      display: block;
    }
  }

  .notifications-banner-section {
    margin: 0 20px;
  }

  .notification-banner {
    width: 80vw;
    padding: 14px 20px;
    margin-bottom: 10px;
    color: $black-base;
    line-height: 1.3;
    max-width: 1500px;
    border: 1px solid $orange-base;
    margin: 10px auto 0;
    border-radius: 5px;
    line-height: 200%;

    button {
      color: $darkgrey-base;
      cursor: pointer;
      text-decoration: none;
    }
    button:hover {
      color: $darkgrey-light;
      text-decoration: underline;
    }
    .tooltip-icon {
      width: 25px;
      height: 25px;
      margin: 4px 25px 4px 10px;
      filter: invert(41%) sepia(65%) saturate(5396%) hue-rotate(333deg)
        brightness(74%) contrast(105%);
    }
  }

  .notification-banner.level-default {
    background-color: color.scale(
      $orange-base,
      $alpha: 10%,
      $saturation: 50%,
      $lightness: 85%
    );
    img {
      filter: invert(41%) sepia(65%) saturate(5396%) hue-rotate(333deg)
        brightness(74%) contrast(105%);
    }
  }

  .notification-banner.level-success {
    background-color: color.scale(
      $color-green,
      $alpha: 10%,
      $saturation: 50%,
      $lightness: 85%
    );
    border: 1px solid $color-green;
    img {
      filter: invert(41%) sepia(65%) saturate(100%) hue-rotate(98deg)
        brightness(74%) contrast(105%);
    }
  }

  .notification-banner.level-note {
    background-color: color.scale(
      $color-blue,
      $alpha: 10%,
      $saturation: 50%,
      $lightness: 85%
    );
    border: 1px solid $color-blue;
    img {
      filter: invert(41%) sepia(65%) saturate(100%) hue-rotate(229deg)
        brightness(74%) contrast(105%);
    }
  }
  .notification-banner.level-warn,
  .notification-banner.level-warning {
    background-color: color.scale(
      $color-purple,
      $alpha: 10%,
      $saturation: 50%,
      $lightness: 85%
    );
    border: 1px solid $color-purple;
    img {
      filter: invert(41%) sepia(65%) saturate(100%) hue-rotate(286deg)
        brightness(74%) contrast(105%);
    }
  }
  .notification-banner.level-danger {
    background-color: color.scale(
      $color-red,
      $alpha: 10%,
      $saturation: 50%,
      $lightness: 85%
    );
    border: 1px solid $color-red;
  }
}

.header.nav-height-small {
  .navlink a {
    padding: 30px 20px;
  }

  @media (max-width: 1200px) {
    .navlink a {
      padding: 20px 10px;
    }
  }
}

.header.nav-height-big {
  .navlink a {
    padding: 30px 20px;
  }

  @media (max-width: 1200px) {
    .navlink a {
      padding: 30px 10px;
    }
  }
}

.nav {
  display: flex;
  gap: 32px;

  @media (max-width: 1200px) {
    gap: 8px;
  }
}

.navlink {
  list-style: none;
  margin: 0;
}

.button-link {
  appearance: none;
  background-color: transparent;
  border: none;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }
}

.navlink a,
.button-link {
  color: $white-base;
  font-family: 'LatoRegular';
  font-size: calc(12 / 16 * 1rem);
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.35px;
  @media (max-width: 1200px) {
    font-size: 10px;
  }
}

.navDropdown:hover .button-link {
  color: $darkgrey-base;
}

.navlink .disabled-link {
  text-transform: uppercase;
  text-decoration: none;
  color: $grey-base;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  opacity: 0.5;
}

li {
  font-family: 'LatoRegular';
}
