.merchant-portal {
  --cui-primary: #d03127;
  --cui-secondary: #981c23;
  --cui-primary-rgb: 208, 49, 39;
  --cui-secondary-rgb: 152, 28, 36;
  --cui-body-color: #333;
  --cui-primary-text-emphasis: #333;

  .core-ui {
    @import '@coreui/coreui/scss/coreui';

    // Buttons
    .btn-primary {
      --cui-btn-color: #fff;
      --cui-btn-bg: var(--cui-primary);
      --cui-btn-border-color: var(--cui-primary);
      --cui-btn-hover-color: #fff;
      --cui-btn-hover-bg: var(--cui-secondary);
      --cui-btn-hover-border-color: var(--cui-secondary);
      --cui-btn-active-color: #fff;
      --cui-btn-active-bg: var(--cui-secondary);
      --cui-btn-active-border-color: var(--cui-secondary);
      --cui-btn-disabled-color: #fff;
      --cui-btn-disabled-bg: #999999;
      --cui-btn-disabled-border-color: #999999;
    }

    .btn-outline-primary {
      --cui-btn-color: var(--cui-primary);
      --cui-btn-border-color: var(--cui-primary);
      --cui-btn-hover-color: #fff;
      --cui-btn-hover-bg: var(--cui-secondary);
      --cui-btn-hover-border-color: var(--cui-secondary);
      --cui-btn-active-color: #fff;
      --cui-btn-active-bg: var(--cui-secondary);
      --cui-btn-active-border-color: var(--cui-secondary);
      --cui-btn-disabled-color: var(--cui-primary);
    }
    .btn-lg {
      --cui-btn-padding-y: 0.75rem;
    }

    // Tabs
    .nav-underline-border {
      button.nav-link.active {
        border-bottom-width: 4px;
      }
      --cui-nav-underline-border-gap: 4px;
    }

    .nav-underline-border .nav-link.active {
      color: #00000e;
      border-bottom-color: var(--cui-primary);
    }

    .nav-underline-border .nav-link:hover {
      color: #555;
      border-bottom-color: var(--cui-secondary);
    }

    // Cards
    .card-img-top {
      margin: 20px 0;
    }

    // Accordions
    .accordion {
      --cui-accordion-btn-padding-y: 10px;
      --cui-accordion-btn-focus-box-shadow: rgba(0.2, 0.2, 0.2, 0) 0 0 0 0.2rem;
      --cui-accordion-color: var(--cui-body-color);
      --cui-accordion-bg: var(--cui-body-bg);
      --cui-accordion-border-color: var(--cui-border-color);
      --cui-accordion-btn-color: var(--cui-body-color);
      --cui-accordion-active-color: var(--cui-primary-text-emphasis);
      --cui-accordion-active-bg: #fff;
    }

    // Enc coreui
  }

  // End portal
}
