.section-separator {
  position: absolute;
  left: 0vw;

  width: 100%;

  hr {
    border-color: $darkgrey-lighter;
    border-width: 2px;
    margin: 0;
  }
}
