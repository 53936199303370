.code-input-container {
  display: flex;
  flex-direction: row;
  .digit-input {
    border: 2px solid $grey-base;
    width: 60px;
    height: 75px;
    margin: 0 30px 0 0;
    border-radius: 10px;
    background-color: rgba($red-base, 0.06);
    text-align: center;
    font-size: 16px;
  }
  @media (max-width: 420px) {
    .digit-input {
      width: 40px;
      height: 50px;
      margin: 0 15px 0 0;
    }
  }
}
