.refund-request {
  &--header {
    display: flex;
    background: $lightgrey-base;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    font-weight: bold;
    height: 50px;

    button {
      margin-right: 12px;
    }
  }
  .refund-amount {
    width: 40%;
  }
  label {
    color: $black-base;
  }
}

.refund-line-items {
  margin-bottom: 32px;
  .line-item-table-container {
    margin-top: 20px;
    border: 1px solid $lightgrey-base;
    border-radius: 4px;
    width: 100%;
  }
  table {
    width: 100%;
    background-color: transparent;
    border: none !important;
    thead {
      border: none;
    }
    tbody {
      border-top: 1px solid $lightgrey-base;
      tr {
        background-color: transparent;
        &:not(:first-child):not(:last-child) {
          border-top: 1px solid $lightgrey-base;
          border-bottom: 1px solid $lightgrey-base;
        }
      }
    }
  }
  input[type='checkbox'] {
    border-color: $grey-base !important;
    &:checked {
      background-color: $black-base;
      border-color: $black-base !important;
    }
  }
  .checkbox-column,
  .id-column {
    width: 50px;
  }
}

.lto-refund-confirm-modal {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 0 20px 20px 20px;
  .modal-inner {
    margin: 0;
    padding: 0 !important;
  }
  h2 {
    margin-bottom: 30px;
  }
  button {
    height: 48px;
    width: 392px;
    margin-bottom: 10px;
  }
}
