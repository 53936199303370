// Typography Variables
// ========================================

// Font Families

$primary-sans-serif: "'LatoRegular'", 'Open Sans', sans-serif;

// Sizes
$base-font-size: 16px;

$s-giga: 60px;
$s-mega: 48px;

$s-huge: 36px;
$s-large: 27px;
$s-bigger: 22px;
$s-big: 18px;
$s-medium: 15.5px;
$s-base: 13px;
$s-small: 11px;
$s-tiny: 10px;

// Line Heights
$base-line-height: $base-font-size * 1.5;
$base-line-loose: $base-font-size * 1.8;
$base-line-small: $base-font-size;

// Font Weights
$regular: 400;
$semibold: 600;
$bold: 700;
