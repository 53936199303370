// Base Typography
// ========================================

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  @include font-type($primary-sans-serif, normal, normal);
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black-base;
  @include font-type($primary-sans-serif, normal, $regular);
  @include rem(margin-bottom, 12px);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  a {
    @include font-type($primary-sans-serif, normal, $regular);

    &:hover {
      text-decoration: underline;
    }
  }
}

h1 {
  font-size: $base-font-size * 1.75;
  @include grid-media($tablet) {
    font-size: $base-font-size * 2.75;
  }
}
h2 {
  font-size: $base-font-size * 2;
  @include grid-media($tablet) {
    font-size: $base-font-size * 2.5;
  }
}
h3 {
  font-size: $base-font-size * 1.5;
  @include grid-media($tablet) {
    font-size: $base-font-size * 2.25;
  }
}
h4 {
  font-size: $base-font-size * 1.25;
  @include grid-media($tablet) {
    font-size: $base-font-size * 1.5;
  }
}
h5 {
  font-size: $base-font-size * 1;
  @include grid-media($tablet) {
    font-size: $base-font-size * 1.25;
  }
}
h6 {
  font-size: $base-font-size - 2;
  @include grid-media($tablet) {
    font-size: $base-font-size * 1;
  }
}

p {
  color: $black-base;
  font-weight: normal;
  font-style: normal;
  @include font-type($primary-sans-serif, normal, $regular);
  @include s-base;
  @include rem(margin-bottom, 12px);
  line-height: $base-line-loose;

  &:last-child {
    @include rem(margin-bottom, 0);
  }
}

li,
dd {
  color: $black-base;
  @include font-type($primary-sans-serif, normal, normal);
  @include s-base;
  line-height: $base-line-height;
  list-style: none;
}

a {
  color: $black-base;
  cursor: pointer;
  @include s-base;
  @include font-type($primary-sans-serif, normal, $bold);
  text-decoration: underline;
  transition: all 0.5s ease-in-out;

  &:hover,
  &:focus {
    color: $black-base;
  }
}

strong,
b {
  font-weight: $bold;
}
i,
em {
  font-style: italic;
}

u {
  text-decoration: underline;
}

.font-family-italic {
  font-family: "'LatoItalic'";
}
.font-family-thin {
  font-family: "'LatoThin'";
}
.font-family-regular {
  font-family: "'LatoRegular'";
}
.font-family-bold {
  font-family: "'LatoBold'";
}
.font-family-black {
  font-family: "'LatoBlack'";
}
