body.modal-open {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;

    .auto {
      top: auto;
      left: auto;
    }
  }
}

.select-location-modal {
  height: 460px;
}

.modal {
  &.is-active {
    opacity: 1;
    visibility: visible;

    .modal-close {
      width: 50px;
    }
  }

  &.is-inactive {
    opacity: 0;
    visibility: hidden;
    display: none;
  }

  .modal-fade-screen {
    background-color: rgba($black-base, 0.65);
    @include position(fixed, 0 0 0 0);
    opacity: 1;
    transition: opacity 0.25s ease;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-close-btn-container {
    width: 100%;
    text-align: right;
    position: sticky;
    top: 0;
  }

  .modal-close {
    background-color: transparent;
    border: none;
    color: $black-base;
    cursor: pointer;
    @include s-huge;

    &:focus {
      @include outline-dark;
    }

    text-align: right;
    width: auto;
    margin-bottom: 10px;
  }

  .fit-auto {
    height: auto !important;
  }

  .modal-inner {
    background-color: $lightgrey-lighter;
    box-shadow: none;
    -ms-overflow-style: none;
    @include grid-container;
    @include rem(margin, 20px);
    @include rem(padding, 20px);
    overflow: auto;
    position: relative;
    transition: opacity 0.25s ease;
    width: 100%;

    display: flex;
    flex-direction: column-reverse;
    max-width: 700px;
    border-radius: 5px;

    &.with-handle {
      border-top: 24px solid $red-base;
    }

    @include grid-media($tablet) {
      @include rem(padding, 30px);
      max-height: 90vh;
      padding-top: 20px;
      width: auto;
    }

    .button-block {
      button,
      a {
        @include rem(margin-top, 10px);
        width: 100%;

        @include grid-media($tablet) {
          width: inherit;
        }

        & ~ button,
        & ~ a {
          @include grid-media($tablet) {
            @include rem(margin-left, 10px);
          }
        }
      }
    }
  }
}

// General alert modal
.modal {
  .alert-modal {
    border-top: 10px solid $red-base;
    font-family: 'LatoRegular';
    height: auto;
    max-width: var(--max-modal-width);

    .modal-content {
      display: flex;
      align-items: center;
      flex-direction: column;

      header {
        margin-top: 16px;

        h1 {
          font-family: 'LatoBlack';
          font-size: 1.75rem;
        }
      }

      p {
        font-family: inherit;
      }

      button {
        border-width: 2px;
        letter-spacing: 1.4px;
        margin-top: 48px;
        text-transform: uppercase;
      }
    }
  }
}

.mui-modal-close-btn {
  position: fixed;
  top: 0;
  right: 0;
}
.mui-modal-close-btn button {
  font-size: clamp(24px, 2.4vw, 44px);
  color: $white-base;
  background: transparent;
  border: none;
  padding: 1.5vw;
  cursor: pointer;
}
.mui-modal-close-btn button:hover {
  color: $grey-base;
}
