.report-detail {
  display: flex;
  font-family: 'LatoRegular';
  justify-content: center;
  margin: 48px auto;
  position: relative;

  .report-detail-options label {
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  a {
    color: $black-base;
    font-family: inherit;
    font-size: calc(14 / 16 * 1rem);
    font-weight: unset;
  }

  td {
    font-family: inherit;
  }

  &__container {
    width: 100%;
  }

  &__summary {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    th:first-child {
      text-align: center;
    }
  }

  &__summary-detail {
    overflow-x: auto;
  }

  &__headers {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .large-report-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90vw;
  }

  .title {
    font-family: "'LatoBold'";
    color: $black-base;
  }
  .detail-table {
    table-layout: auto;
  }

  &--title {
    font-size: 30px;
  }

  &--disclaimer {
    margin-top: 50px;
  }

  &--sub-title {
    font-size: 20px;
  }

  .sales-report {
    th {
      width: 120px;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    font-family: 'LatoRegular';

    a,
    button {
      font-family: inherit;
      font-size: calc(12 / 16 * 1rem);
      font-weight: 700;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
    }
  }
  .export-to-pdf {
    text-decoration: underline;
    background: unset;
    border: unset;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    text-decoration: underline;
    transition: all 0.5s ease-in-out;
    color: $black-base;
    cursor: pointer;
  }

  @media print {
    .report-detail {
      .button-primary {
        display: none;
      }

      &__headers {
        a {
          display: none;
        }
        .export-to-pdf {
          display: none;
        }
      }
    }
  }
}

.date-filter-report-detail {
  display: flex;
  align-items: center;
  gap: 20px;
  .date-selection {
    display: flex;
    gap: 50px;
    fieldset {
      margin-bottom: unset !important;
    }
  }
  .react-datepicker-popper {
    width: 500px !important;
  }
}

.select-all {
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  color: $black-base;
}

.select-all-td {
  padding: 0.75rem 0.125rem !important;
}
