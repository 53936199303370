.sale-initiator-modal {
  height: auto !important;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    flex-direction: column;
  }

  .borrower-container {
    display: flex;
    width: 100%;
    border-top: 1px solid $grey-base;
    padding-top: 5%;
    margin-bottom: 5%;

    .primary-borrower {
      width: 50%;

      .radio-group {
        width: 90%;
        margin-top: 20px;
        text-align: left;
        padding-left: 20%;
      }
    }

    .authorized-buyer {
      width: 50%;

      .radio-group {
        width: 90%;
        margin-top: 20px;
        text-align: left;
        padding-left: 21%;
      }
    }

    .radio-wrapper {
      display: flex;
    }
  }

  .separator {
    position: relative;
    border: 1px solid $grey-base;
    height: 100px;

    .or {
      position: absolute;
      top: 30%;
      left: -22px;
      background-color: $white-base;
      padding: 10px;
      font-size: 16px;
      color: $grey-base;
    }
  }

  label {
    font-weight: normal;
    font-family: 'LatoRegular';
    font-size: 16px;
    line-height: normal;
    vertical-align: middle;
  }

  input[type='radio'] {
    border: 1px solid $black-base !important;
    height: 18px !important;
    width: 18px !important;
    accent-color: $black-base;
    margin: 2px 10px 0 10px;
  }
}
