.refund-complete {
  &__receipt {
    border: 1px solid $darkgrey-base;
    &--header {
      padding: 16px;
      background-color: $black-base;
      color: $white-base !important;
    }

    &--body {
      overflow: auto;
    }
    &--info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      font-size: 20px;
      .key {
        text-align: left;
        letter-spacing: 0px;
        opacity: 1;
        margin-bottom: 0;
      }
      .value {
        margin-bottom: 0;
      }
      .disclaimer {
        font-size: 14px;
        line-height: 19px;
        p {
          text-indent: 10px;
        }
      }
    }
    &--info-container {
      padding: 15px;
    }
    .seperator {
      border-bottom: 1px solid $darkgrey-base;
      width: 100%;
    }
  }
}

@media (max-width: 700px) {
  .refund-complete {
    &__receipt {
      &--info {
        .value {
          text-align: end;
        }
      }
    }
  }
}
