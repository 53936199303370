.add-signer-inner-container {
  min-width: 500px;
  padding: 0 24px 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  &.on-add-signer-page {
    align-items: flex-start;
    p {
      margin-bottom: 12px;
    }
  }
  h1 {
    font-size: 32px;
    padding-bottom: 16px;
    font-family: "'LatoBold'";
    color: $black-base;
  }
  p {
    font-family: 'LatoRegular';
    color: $black-base;
  }
  .add-signer-form-container,
  .success-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .icon {
      width: 90px;
      padding-bottom: 32px;
    }
    .input-container-single {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 12px;
      label {
        padding: 16px 0 0 0;
        font-family: "'LatoBold'";
        font-size: 18px;
        color: $black-base;
        &.invalid {
          color: $red-base;
        }
      }
      input {
        border: none;
        border-bottom: 1px solid $black-base;
        padding: 10px 0 10px 0;
        font-size: 18px;
        font-family: 'LatoRegular';
        &.invalid {
          border-bottom: 1px solid $red-base;
          background-color: $red-light;
        }
      }
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .submit-or-cancel-buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 32px 0 32px 0;
        button {
          margin-bottom: 16px;
          width: 75%;
          padding: 16px;
        }
      }
      span {
        position: absolute;
        margin-top: 90px;
        color: $red-base;
        font-size: 16px;
        font-family: 'LatoRegular';
        max-width: 35%;
      }
    }
  }
  .success-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    p {
      font-size: 18px;
      font-family: 'LatoRegular';
      color: $black-base;
      margin-bottom: 48px;
      text-align: center;
    }
  }
}
.add-signer-page {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
