.too-zoom-shell {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000001;
  display: flex;
  align-items: center;
  justify-content: center;
}
.too-zoom-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;
  width: 100%;
  height: 100%;
  background: rgba($black-base, 0.5);
}
.too-zoom {
  width: 50vw;
  height: 40vh;
  background: $white-base;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.too-zoom h2 {
  line-height: 100%;
  font-size: 3vw;
}
.too-zoom p {
  line-height: 100%;
  font-size: 1vw;
}

.too-zoom button {
  font-size: 10px;
}
