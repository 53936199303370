section.applicant-container {
  font-family: 'LatoRegular';
  height: calc(100vh - 200px);
  margin: 0 auto;
  max-width: 800px;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  @media (max-width: 575.98px) {
    margin: 0 16px;
  }

  header {
    font-family: "'LatoBold'";
    margin-bottom: 32px;

    h1 {
      font-family: inherit;
      font-size: 2rem;
      margin-bottom: 2rem;
      text-align: center;
    }

    p {
      font-family: 'LatoRegular';
      font-size: 1.25rem;
      font-weight: 400;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .input-container-single {
      width: 100%;
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      input {
        border: none;
        border-bottom: 1px solid $black-base;
        background-color: $lightblue-base;
        font-family: inherit;
        padding: 0.5rem 0 !important;
        &.invalid {
          border-bottom: 1px solid $red-base;
          background-color: $red-light;
        }
        &::placeholder {
          font-style: normal;
        }
      }
      label {
        font-family: inherit;
      }
      span {
        font-size: 14px;
        color: $red-base;
        font-family: 'LatoRegular';
        width: 100%;
        min-height: 16px;
      }
    }
    button {
      width: 90%;
    }
  }
}

// These styles can/should be removed when an overall, consistent set of
// form styles, applied to all consumer portal forms, is developed, along
// the lines of the styles above.
.applicant-personal-info {
  font-family: 'LatoRegular';
  margin: 0 auto;
  max-width: 800px;
  padding: 50px 0;

  @media (max-width: 575.98px) {
    margin: 0 16px;
  }
}
