.auth-landing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(100vh + 300px);
  z-index: 5;
  background-color: $black-base;
  position: absolute;
  top: -300px;
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $white-base;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    border-color: $white-base;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-top: 36px;
      .logo {
        width: 52px;
        height: 52px;
        margin-bottom: 24px;
      }
      .input-container {
        position: relative;
        input {
          padding: 18px;
          width: 320px;
          font-family: ulp-font, -apple-system, 'system-ui', Roboto, Helvetica,
            sans-serif;
          font-size: 16px;
          color: $black-base;
          border-radius: 3px;
          border-color: $grey-base;
        }
        .label-line {
          font-family: ulp-font, -apple-system, 'system-ui', Roboto, Helvetica,
            sans-serif;
          color: $black-light;
          font-size: 14px;
          position: absolute;
          top: -5px;
          left: 7px;
          background-color: $white-base;
          margin: 0;
          padding: 0 5px;
          line-height: 14px;
        }
      }
      button {
        background-color: $red-base;
        border: none;
        color: $white-base;
        padding: 18px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        width: 320px;
        height: 52px;
        margin-bottom: 24px;
        margin-top: 24px;
      }
      h1 {
        font-family: ulp-font, -apple-system, 'system-ui', Roboto, Helvetica,
          sans-serif;
        color: $black-base;
        font-size: 24px;
        font-weight: 400px;
        margin-bottom: 24px;
      }
      p {
        font-family: ulp-font, -apple-system, 'system-ui', Roboto, Helvetica,
          sans-serif;
        font-size: 14px;
        color: $black-base;
        width: 80%;
        text-align: center;
        margin-bottom: 24px;
      }
    }
  }
}

@media (max-width: 500px) {
  .auth-landing-container {
    background-color: $white-base;
    align-items: flex-start;
    .card {
      padding-top: 30%;
    }
  }
}
