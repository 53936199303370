/*-----------------------
Table
-----------------------*/
table {
  background-color: $lightgrey-lighter;
  table-layout: fixed;
  width: 100%;

  thead {
    border-bottom: 2px solid $black-base;
  }

  th,
  td {
    display: table-cell;
    @include rem(margin, 15px 5px);
    @include rem(padding, 12px 10px);
    @include s-base;
    text-align: left;
    width: inherit;
    vertical-align: middle;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  th,
  td:before {
    color: $darkgrey-light;
    font-weight: $bold;
    @include s-base;
  }

  th {
    color: $black-base;
    width: 100%;
  }

  th.sortable {
    cursor: pointer;
  }

  tbody {
    overflow: auto;

    tr {
      &.clickable {
        cursor: pointer;
      }
      &:nth-child(even) {
        background-color: $lightgrey-light;
      }
    }
  }
}

/*-----------------------
Scrollable
-----------------------*/
.scrollable-table {
  overflow: auto;
}

/*-----------------------
Filter Table Arrows
-----------------------*/
th.sortable {
  &:after {
    display: inline-block;
    font-size: 12px;
    @include rem(margin-left, 10px);
    opacity: 0.4;
  }

  &.order-descend:after {
    background-image: url('../images/utility-icons/dropdown.svg');
  }
  &.order-ascend:after {
    background-image: url('../images/utility-icons/dropdown.svg');
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: “FlipV”;
  }
  &:not(.order-ascend):not(.order-descend):hover:after {
    background-image: url('../images/utility-icons/dropdown.svg');
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: “FlipV”;
  }
}

.sortable-table {
  th.col-width-140px {
    width: 140px;
  }
  th.col-width-170px {
    width: 170px;
  }
}

.filterable-sortable-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;

  thead {
    background-color: $darkgrey-lighter;
    border: none;
  }
  th {
    color: $black-base;
    font-weight: $bold;
  }
  .reports-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 100px;
  }

  .sort-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-height: 24px;
  }
  .sort-or-filter-icon {
    cursor: pointer;
    position: relative;
    left: 5px;
  }
  .filter-close {
    width: 25px;
    height: 25px;
    padding: 5px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }
  .filterable-sortable-pagination {
    padding: 15px 0 15px 0;
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    button {
      cursor: pointer;
      border: none;
      background-color: transparent;
    }
  }
}
.filter-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 1px solid $black-base;
  padding: 30px;
  background-color: $white-base;

  button {
    margin: 10px;
  }
}
.input-and-reset {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .reset-icon {
    padding: 0 10px 0 10px;
  }
}

.table-hover td {
  cursor: pointer;
}
