.merchant-portal {
  .container {
    min-height: 65vh;
  }
  .edit-information {
    display: flex;
    flex-direction: row;
    width: 80%;
    background-color: $grey-light;
    padding: 15px;
  }

  form {
    width: 90%;
  }
  .loan-adjustment-form {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    label {
      font-size: 15px;
    }
    .loan-amount-input {
      width: 50%;
      padding-right: 25px;
    }
    .date-selection {
      padding-left: 25px;
      width: 50%;
    }
  }
  .dashboard {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    flex-direction: column;
    align-items: center;

    &--header {
      color: $black-base;
      font-size: 30px;
      font-family: "'LatoBold'";
      line-height: 38px;
    }
    &--header2 {
      color: $black-base;
      font-size: 25px;
      font-family: "'LatoBold'";
      line-height: 34px;
    }
    &--header3 {
      color: $black-base;
      font-size: 20px;
      font-family: "'LatoBold'";
      line-height: 30px;
    }

    &__actions {
      display: flex;
      justify-content: space-around;
      margin: 32px auto 0;
      width: 100%;
      column-gap: 1.8vw;
      max-width: 1240px;

      &--item {
        display: flex;
        justify-content: center;
        max-width: 385px;
        width: 18vw;
        height: 220px;
        border: 1px solid $darkgrey-lighter;
        border-radius: 10px;
        column-gap: 20px;
        background: $white-base;
        box-shadow: 0 1px 2px rgba($black-base, 0.15);
        transition: box-shadow 0.3s ease-in-out;

        &:hover {
          box-shadow: 0 5px 15px rgba($black-base, 0.3);
          cursor: pointer;
        }

        .action-content {
          display: flex;
          justify-content: space-evenly;
          flex-direction: column;
          align-items: center;
          &--title {
            text-transform: uppercase;
            font-size: 14px;
            font-family: "'LatoBold'";
            letter-spacing: 1.2px;
          }
          &--description {
            font-size: 18px;
            color: $black-base;
            font-family: 'LatoLight';
          }
        }

        @media (max-width: 1200px) {
          width: 25vw;
        }
      }
    }

    &__transactions {
      width: 90%;
      &--title {
        display: flex;
        color: $black-base;
        font-size: 20px;
        font-family: "'LatoBold'";
      }
      &--table {
        table {
          border: 1px solid $darkgrey-lighter;
        }
        th {
          background: $darkgrey-lighter;
          color: $black-base;
          font-family: 'LatoRegular';
          font-size: 13px;
          letter-spacing: 1px;
        }
        thead {
          border-bottom: unset;
        }
        th,
        td {
          margin: unset;
        }
      }
      .actions {
        a:first-of-type {
          margin-right: 10px;
        }
      }
    }

    &__activities {
      width: 90%;
      &--title {
        display: flex;
        color: $black-base;
        font-size: 20px;
        font-family: "'LatoBold'";
      }
      &--table {
        border: 1px solid $darkgrey-lighter;
        border-radius: 10px;
        padding: 20px;

        table {
          th {
            background: $darkgrey-lighter;
            color: $black-base;
            font-family: 'LatoRegular';
            font-size: 13px;
            letter-spacing: 1px;
          }
          thead {
            border-bottom: unset;
          }
          td {
            margin: unset;
          }
        }
      }
      .actions {
        display: flex;
        gap: 20px;
      }
    }
  }
}
.activity-filter,
.transaction-filter {
  display: flex;
  justify-content: center;
  padding: 15px 10px;
  gap: 10px;
  &--item {
    width: 35%;
    label {
      margin-bottom: unset !important;
    }
  }
  fieldset {
    margin-bottom: unset !important;
  }

  .checkbox-group,
  .radio-group {
    width: 45%;
    .label {
      margin-bottom: 20px;
    }
  }
  .center-align {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .CheckboxGroup {
    display: flex;
    justify-content: center;
  }
  .RadioGroup {
    display: flex;
    label {
      cursor: pointer;
      float: right;
      margin: 2px 0px 0px;
      margin: 0.125rem 0px 0px;
      line-height: normal;
      color: $black-base;
      font-size: 14px;
    }

    fieldset {
      display: flex;
      flex-flow: row-reverse;
      width: unset;
      margin-right: 20px;
    }
    input[type='radio'] {
      border: 1px solid $black-base !important;
      padding: unset !important;
      height: 22px !important;
      width: 22px !important;
      accent-color: $black-base;
    }
  }
  .react-datepicker__navigation {
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
  }
  label {
    color: $black-base;
    font-size: 14px;
  }
}

.disabled-link {
  text-decoration: none;
  color: $darkgrey-lighter;
  cursor: not-allowed;
  opacity: 0.5;
}

.resend-app-modal-content {
  border-top: 30px solid $red-base;
  border-bottom: 30px solid transparent;
  min-width: 400px;
  min-height: 300px;
  max-width: 40% !important;
  max-height: 50% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.resend-app-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: $black-base;
  font-weight: bold;
  font-size: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.resend-app-form {
  display: flex;
  flex-direction: column;
  align-items: space-around;

  .title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 30px;
  }
  .submit-button-container {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .row {
    padding: 10px 0 10px 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    div {
      padding-right: 20px;
    }
    .confirmation-checkbox {
      border: 5px solid $blue-base;
      margin-left: 8px;
    }
  }
  input {
    width: 80%;
  }
  h4 {
    color: $black-base;
    font-family: "'LatoBold'";
  }
}
