.storybook-reset-bar {
  background: $black-base;
  padding: 10px;
  text-align: center;
  button {
    background: $grey-base;
    border: 1px solid $darkgrey-light;
    color: $black-base;
    border-radius: 2px;
    padding: 5px 20px;
    cursor: pointer;
  }
}
