//The following two rules come from the CoreUI framework and are under their theme styles, if the package is to be updated,
//this will need to be updated as well.
:root,
[data-coreui-theme='light'] {
  --cui-blue: #0d6efd;
  --cui-indigo: #6610f2;
  --cui-purple: #6f42c1;
  --cui-pink: #d63384;
  --cui-red: #dc3545;
  --cui-orange: #fd7e14;
  --cui-yellow: #ffc107;
  --cui-green: #198754;
  --cui-teal: #20c997;
  --cui-cyan: #0dcaf0;
  --cui-black: #080a0c;
  --cui-white: #fff;
  --cui-gray: #6d7d9c;
  --cui-gray-dark: #323a49;
  --cui-gray-100: #f3f4f7;
  --cui-gray-200: #e7eaee;
  --cui-gray-300: #dbdfe6;
  --cui-gray-400: #cfd4de;
  --cui-gray-500: #aab3c5;
  --cui-gray-600: #6d7d9c;
  --cui-gray-700: #4a566d;
  --cui-gray-800: #323a49;
  --cui-gray-900: #212631;
  --cui-primary: #5856d6;
  --cui-secondary: #6b7785;
  --cui-success: #1b9e3e;
  --cui-info: #39f;
  --cui-warning: #f9b115;
  --cui-danger: #e55353;
  --cui-light: #f3f4f7;
  --cui-dark: #212631;
  --cui-primary-rgb: 88, 86, 214;
  --cui-secondary-rgb: 107, 119, 133;
  --cui-success-rgb: 27, 158, 62;
  --cui-info-rgb: 51, 153, 255;
  --cui-warning-rgb: 249, 177, 21;
  --cui-danger-rgb: 229, 83, 83;
  --cui-light-rgb: 243, 244, 247;
  --cui-dark-rgb: 33, 38, 49;
  --cui-primary-text-emphasis: #3634a3;
  --cui-secondary-text-emphasis: #212233;
  --cui-success-text-emphasis: #0f5722;
  --cui-info-text-emphasis: #184c77;
  --cui-warning-text-emphasis: #764705;
  --cui-danger-text-emphasis: #671414;
  --cui-light-text-emphasis: #4a566d;
  --cui-dark-text-emphasis: #323a49;
  --cui-primary-bg-subtle: #cfc7f3;
  --cui-secondary-bg-subtle: #ced2d8;
  --cui-success-bg-subtle: #cbedd6;
  --cui-info-bg-subtle: #c0e6ff;
  --cui-warning-bg-subtle: #feecc5;
  --cui-danger-bg-subtle: #f9d4d4;
  --cui-light-bg-subtle: #f9fafb;
  --cui-dark-bg-subtle: #cfd4de;
  --cui-primary-border-subtle: #9d92e6;
  --cui-secondary-border-subtle: #9da5b1;
  --cui-success-border-subtle: #96dbad;
  --cui-info-border-subtle: #80c6ff;
  --cui-warning-border-subtle: #fcd88a;
  --cui-danger-border-subtle: #f2a9a9;
  --cui-light-border-subtle: #e7eaee;
  --cui-dark-border-subtle: #aab3c5;
  --cui-white-rgb: 255, 255, 255;
  --cui-black-rgb: 8, 10, 12;
  --cui-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --cui-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  --cui-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --cui-body-font-family: var(--cui-font-sans-serif);
  --cui-body-font-size: 1rem;
  --cui-body-font-weight: 400;
  --cui-body-line-height: 1.5;
  --cui-body-color: rgba(37, 43, 54, 0.95);
  --cui-body-color-rgb: 37, 43, 54;
  --cui-body-bg: #fff;
  --cui-body-bg-rgb: 255, 255, 255;
  --cui-emphasis-color: #080a0c;
  --cui-emphasis-color-rgb: 8, 10, 12;
  --cui-secondary-color: rgba(37, 43, 54, 0.681);
  --cui-secondary-color-rgb: 37, 43, 54;
  --cui-secondary-bg: #e7eaee;
  --cui-secondary-bg-rgb: 231, 234, 238;
  --cui-tertiary-color: rgba(37, 43, 54, 0.38);
  --cui-tertiary-color-rgb: 37, 43, 54;
  --cui-tertiary-bg: #f3f4f7;
  --cui-tertiary-bg-rgb: 243, 244, 247;
  --cui-body-color-dark: rgba(255, 255, 255, 0.87);
  --cui-body-color-rgb-dark: 255, 255, 255;
  --cui-body-bg-dark: #212631;
  --cui-body-bg-rgb-dark: 33, 38, 49;
  --cui-emphasis-color-dark: #fff;
  --cui-emphasis-color-rgb-dark: 255, 255, 255;
  --cui-secondary-color-dark: rgba(255, 255, 255, 0.6);
  --cui-secondary-color-rgb-dark: 255, 255, 255;
  --cui-secondary-bg-dark: #323a49;
  --cui-secondary-bg-rgb-dark: 50, 58, 73;
  --cui-tertiary-color-dark: rgba(255, 255, 255, 0.38);
  --cui-tertiary-color-rgb-dark: 255, 255, 255;
  --cui-tertiary-bg-dark: #2a303d;
  --cui-tertiary-bg-rgb-dark: 42, 48, 61;
  --cui-high-emphasis: rgba(37, 43, 54, 0.95);
  --cui-medium-emphasis: rgba(37, 43, 54, 0.681);
  --cui-disabled: rgba(37, 43, 54, 0.38);
  --cui-high-emphasis-inverse: rgba(255, 255, 255, 0.87);
  --cui-medium-emphasis-inverse: rgba(255, 255, 255, 0.6);
  --cui-disabled-inverse: rgba(255, 255, 255, 0.38);
  --cui-heading-color: inherit;
  --cui-link-color: #5856d6;
  --cui-link-color-rgb: 88, 86, 214;
  --cui-link-decoration: underline;
  --cui-link-hover-color: #4645ab;
  --cui-link-hover-color-rgb: 70, 69, 171;
  --cui-code-color: #d63384;
  --cui-highlight-color: rgba(37, 43, 54, 0.95);
  --cui-highlight-bg: #fff3cd;
  --cui-border-width: 1px;
  --cui-border-style: solid;
  --cui-border-color: #dbdfe6;
  --cui-border-color-translucent: rgba(8, 10, 12, 0.175);
  --cui-border-radius: 0.375rem;
  --cui-border-radius-sm: 0.25rem;
  --cui-border-radius-lg: 0.5rem;
  --cui-border-radius-xl: 1rem;
  --cui-border-radius-xxl: 2rem;
  --cui-border-radius-2xl: var(--cui-border-radius-xxl);
  --cui-border-radius-pill: 50rem;
  --cui-box-shadow: 0 0.5rem 1rem rgba(8, 10, 12, 0.15);
  --cui-box-shadow-sm: 0 0.125rem 0.25rem rgba(8, 10, 12, 0.075);
  --cui-box-shadow-lg: 0 1rem 3rem rgba(8, 10, 12, 0.175);
  --cui-box-shadow-inset: inset 0 1px 2px rgba(8, 10, 12, 0.075);
  --cui-focus-ring-width: 0.25rem;
  --cui-focus-ring-opacity: 0.25;
  --cui-focus-ring-color: rgba(88, 86, 214, 0.25);
  --cui-form-valid-color: #1b9e3e;
  --cui-form-valid-border-color: #1b9e3e;
  --cui-form-invalid-color: #e55353;
  --cui-form-invalid-border-color: #e55353;
}

[data-coreui-theme='dark'] {
  color-scheme: dark;
  --cui-body-color: rgba(255, 255, 255, 0.87);
  --cui-body-color-rgb: 255, 255, 255;
  --cui-body-bg: #212631;
  --cui-body-bg-rgb: 33, 38, 49;
  --cui-emphasis-color: #fff;
  --cui-emphasis-color-rgb: 255, 255, 255;
  --cui-secondary-color: rgba(255, 255, 255, 0.6);
  --cui-secondary-color-rgb: 255, 255, 255;
  --cui-secondary-bg: #323a49;
  --cui-secondary-bg-rgb: 50, 58, 73;
  --cui-tertiary-color: rgba(255, 255, 255, 0.38);
  --cui-tertiary-color-rgb: 255, 255, 255;
  --cui-tertiary-bg: #2a303d;
  --cui-tertiary-bg-rgb: 42, 48, 61;
  --cui-high-emphasis: rgba(255, 255, 255, 0.87);
  --cui-medium-emphasis: rgba(255, 255, 255, 0.6);
  --cui-disabled: rgba(255, 255, 255, 0.38);
  --cui-primary: #6261cc;
  --cui-secondary: #6b7785;
  --cui-success: #249542;
  --cui-info: #3d99f5;
  --cui-warning: #edad21;
  --cui-danger: #db5d5d;
  --cui-light: #f3f4f7;
  --cui-dark: #212631;
  --cui-gray-100: #f3f4f7;
  --cui-gray-200: #e7eaee;
  --cui-gray-300: #dbdfe6;
  --cui-gray-400: #cfd4de;
  --cui-gray-500: #aab3c5;
  --cui-gray-600: #6d7d9c;
  --cui-gray-700: #4a566d;
  --cui-gray-800: #323a49;
  --cui-gray-900: #212631;
  --cui-primary-rgb: 98, 97, 204;
  --cui-secondary-rgb: 107, 119, 133;
  --cui-success-rgb: 36, 149, 66;
  --cui-info-rgb: 61, 153, 245;
  --cui-warning-rgb: 237, 173, 33;
  --cui-danger-rgb: 219, 93, 93;
  --cui-light-rgb: 243, 244, 247;
  --cui-dark-rgb: 33, 38, 49;
  --cui-primary-text-emphasis: #403f98;
  --cui-secondary-text-emphasis: #212233;
  --cui-success-text-emphasis: #145224;
  --cui-info-text-emphasis: #1f4b70;
  --cui-warning-text-emphasis: #70460b;
  --cui-danger-text-emphasis: #611a1a;
  --cui-light-text-emphasis: #f3f4f7;
  --cui-dark-text-emphasis: #dbdfe6;
  --cui-primary-bg-subtle: #cfc7f3;
  --cui-secondary-bg-subtle: #ced2d8;
  --cui-success-bg-subtle: #cbedd6;
  --cui-info-bg-subtle: #c0e6ff;
  --cui-warning-bg-subtle: #feecc5;
  --cui-danger-bg-subtle: #f9d4d4;
  --cui-light-bg-subtle: #323a49;
  --cui-dark-bg-subtle: #1d222b;
  --cui-primary-border-subtle: #9d92e6;
  --cui-secondary-border-subtle: #9da5b1;
  --cui-success-border-subtle: #96dbad;
  --cui-info-border-subtle: #80c6ff;
  --cui-warning-border-subtle: #fcd88a;
  --cui-danger-border-subtle: #f2a9a9;
  --cui-light-border-subtle: #4a566d;
  --cui-dark-border-subtle: #323a49;
  --cui-heading-color: inherit;
  --cui-link-color: #6261cc;
  --cui-link-hover-color: #8181d6;
  --cui-link-color-rgb: 98, 97, 204;
  --cui-link-hover-color-rgb: 129, 129, 214;
  --cui-code-color: #e685b5;
  --cui-highlight-color: rgba(255, 255, 255, 0.87);
  --cui-highlight-bg: #664d03;
  --cui-border-color: #323a49;
  --cui-border-color-translucent: rgba(255, 255, 255, 0.1);
  --cui-form-valid-color: #75b798;
  --cui-form-valid-border-color: #75b798;
  --cui-form-invalid-color: #ea868f;
  --cui-form-invalid-border-color: #ea868f;
}
// This ends the copied CoreUI CSS
