.full-application {
  width: 80%;
  margin: 0 auto;
  &__content {
    color: $black-base;
    font-size: 20px;
    line-height: 26px;
    font-family: "'LatoBold'";
    a {
      font-style: italic;
      font-size: 20px;
      color: $blue-base;
    }
  }
  &__form {
    &--mid-content {
      p {
        color: $black-base;
      }
      div {
        font-size: 16px;
        color: $black-base;
      }
    }
  }
  .psa {
    line-height: 26px;
    font-family: 'LatoRegular';
  }
  .checkbox {
    width: 4% !important;
  }
  .button-primary {
    width: 50%;
  }
  .checkbox-right li {
    list-style-type: disc !important;
  }
}
