.base-text {
  font-family: 'LatoRegular';
  color: $black-base;
  &.headline-large {
    font-family: "'LatoBold'";
    font-size: 32px;
    line-height: 40px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  &.headline-medium {
    font-family: "'LatoBold'";
    font-size: 24px;
    line-height: 32px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
  &.headline-small {
    font-family: "'LatoBold'";
    font-size: 18px;
    line-height: 24px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  &.title-extra-large {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  &.title-large {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  &.title-medium {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  &.title-small {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  &.title-extra-small {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  &.body-large {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  &.body-medium {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  &.body-small {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.label-large {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  font-family: "'LatoBold'";
  color: $black-base;
  &.unbold {
    font-family: 'LatoRegular';
    font-weight: 400;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
}
.label-medium {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: "'LatoBold'";
  color: $black-base;
  &.unbold {
    font-family: 'LatoRegular';
    font-weight: 400;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
}
.label-small {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-family: "'LatoBold'";
  color: $black-base;
  &.unbold {
    font-family: 'LatoRegular';
    font-weight: 400;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
}
