.application-finder-container {
  display: flex;
  font-family: 'LatoRegular';
  height: calc(100vh - 200px);
  width: 100%;

  p {
    font-family: inherit;
    font-weight: 400;
  }

  a {
    color: $blue-base;
    font-family: inherit;
    font-style: italic;
  }

  .left-content-container {
    flex: 2 1 0;
    img {
      height: 100%;
      object-fit: cover;
      vertical-align: bottom;
      width: 100%;
    }
  }

  .right-content-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    flex: 3 1 0;
    padding: 64px;

    header {
      padding-bottom: 32px;
      text-align: center;
    }
    h1 {
      margin-top: 2rem;
      font-size: 1.5rem;
      font-family: 'LatoBlack';
    }

    .logo {
      height: 2.5rem;
      width: auto;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 75%;
      .input-container-single {
        width: 100%;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        input {
          border: none;
          border-bottom: 1px solid $black-base;
          background-color: $lightblue-base;
          font-family: inherit;
          padding: 0.5rem 0 !important;
          &.invalid {
            border-bottom: 1px solid $red-base;
            background-color: $red-light;
          }
          &::placeholder {
            font-style: normal;
          }
        }
        label {
          font-family: inherit;
        }
        span {
          font-size: 14px;
          color: $red-base;
          font-family: 'LatoRegular';
          width: 100%;
          min-height: 16px;
        }
      }
      button {
        width: 90%;
      }
    }
    .button-primary {
      margin-bottom: 1rem;
      width: 90%;
    }
  }
}

@media (max-width: 900px) {
  .left-content-container {
    display: none;
  }
  .right-content-container {
    form {
      .input-container-single {
        width: 90%;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .application-finder-container {
    min-width: unset;

    .left-content-container {
      display: none;
    }

    .right-content-container {
      padding: 4px 16px;
      width: 100%;
      justify-content: flex-start;

      header img.logo {
        display: none;
      }

      h1 {
        margin-top: 0;
        font-family: "'LatoBold'";
      }

      .button-primary {
        margin-bottom: 1rem;
        width: 100%;
      }
    }
  }
}
