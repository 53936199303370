/*-----------------------
Empty State
-----------------------*/

.empty-state {
  background-color: $grey-light;
  @include center;
  @include rem(padding, 30px);
  max-width: 600px;
  text-align: center;

  img {
    background-color: lighten($darkgrey-light, 10%);
    border-radius: 20em;
    @include rem(margin-bottom, 10px);
    @include rem(padding, 12px);
    max-width: 50px;
  }
}

.empty-state-container {
  text-align: center;
  @include rem(padding, 10px);
}
