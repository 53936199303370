.revolving-plan-warn-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  padding: 0 20px 0 20px;
  h4 {
    font-size: 28px;
    font-family: "'LatoBold'";
    color: $black-base;
    text-align: center;
    margin-bottom: 24px;
  }
  p {
    font-family: 'LatoRegular';
    color: $black-base;
    font-size: 18px;
    margin-bottom: 18px;
    text-align: center;
  }
  button {
    padding: 18px;
    width: 100%;
    margin-bottom: 12px;
  }
}
.sales {
  .data-of-birth {
    width: 35%;
  }
  .service-date {
    font-family: 'LatoRegular';
    font-size: 16px;
    padding-bottom: 20px;

    fieldset {
      margin-bottom: 1rem !important;
    }
  }
}
.one-line-form {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  > div {
    width: 100%;
  }
}
.void-button {
  display: flex;
  justify-content: center;
  button {
    width: 30%;
    text-transform: uppercase;
  }
}

.print-logo {
  display: none;
}

.print-receipt-id-info fieldset {
  margin-bottom: 2rem !important;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  button {
    padding: 1em;
  }
  button,
  .return-to-dashboard {
    margin: 8px 12px 0 12px;
  }
}

@media print {
  .start-application__form--actions {
    display: none !important;
  }

  html,
  body {
    font-size: 10px;
  }

  .disclaimer p,
  .value {
    font-size: 10px;
    margin: 0;
    padding: 0;
  }

  .refund-complete__receipt {
    .key {
      font-size: 10px;
    }
  }
  .input-wrapper {
    width: 50% !important;
    border: none !important;
  }

  fieldset {
    margin: 0;
    input {
      border: none !important;
    }
  }

  .signature-wrapper {
    height: 100%;
    padding-top: 5%;
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    fieldset {
      input {
        border-bottom: 1px solid $black-base !important;
      }
    }
  }

  .account-number {
    &__visibility-button {
      display: none;
    }
  }

  .refund-complete__receipt--header {
    display: none;
  }

  .print-receipt-container {
    display: flex;
    justify-content: center !important;
    flex-grow: 1;
  }

  .print-logo {
    display: initial;
  }
}

.payment-details-form {
  .product-information {
    margin-bottom: 32px;
    fieldset {
      margin-bottom: 4px !important;
    }
    input[type='tel'] {
      font-size: 16px;
    }
    .button-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .btn-link {
        color: $blue-base;
        font-size: 14px;
        cursor: pointer;
        border-bottom: none;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.remove-button {
          color: $red-base;
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }
      .barrier {
        width: 1px;
        height: 8px;
        border-radius: 1px;
        background-color: $lightgrey-base;
        margin: 0 4px;
      }
    }
  }
}
