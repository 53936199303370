/*-----------------------
Spinner
-----------------------*/

#spinner {
  text-align: center;

  &:after {
    animation: spin 0.5s linear infinite;
    border: 3px solid $red-base;
    border-radius: 50%;
    border-top-color: $red-base;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: inline-block;
    height: 30px;
    width: 30px;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.spinner-center-shell {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: $white-base;
  z-index: 100;
}
.spinner-center {
  width: 50vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white-base;
  margin: 0 auto;

  #spinner {
    &:after {
      height: 70px;
      width: 70px;
    }
  }
}
