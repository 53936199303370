.program-management {
  margin-top: 30px;

  h1 {
    font-family: "'LatoBold'";
    color: $black-base;
  }

  .view-programs {
    margin: 30px 0;
    background-color: $lightgrey-light;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;

    p {
      color: $darkgrey-base;
    }
  }

  h5.plan-card-title {
    margin-bottom: 40px !important;
    border-bottom: 5px solid #000;
    padding-bottom: 10px;
  }

  .plan-card-hr {
    border-top: 5px solid #000 !important;
    opacity: 1 !important;
  }

  .plan-feature-row {
    column-gap: 20px;
    margin-top: 20px;
    .plan-feature-icon {
      width: 50px;
      height: 50px;
      img {
        width: 50px;
      }
    }

    h3 {
      font-size: 1.5rem !important;
      color: $red-base;
    }
  }

  .program-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 32px 0;
    border-bottom: 1px solid $grey-base;

    .active-tab-underline,
    .inactive-tab-underline {
      width: 100%;
      height: 4px;
    }
    .active-tab-underline {
      background-color: $red-base;
    }
    .inactive-tab-underline {
      background-color: transparent;
    }

    .program-tab {
      margin-right: 32px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .inactive-tab {
      color: $darkgrey-base;
    }
  }

  .partner-notice {
    background: $lightgrey-light;
    padding: 20px;
    border-radius: 3px;
    line-height: 150%;
    font-size: 16px;
    margin: 20px 0;
  }

  .select-plan {
    th {
      font-size: 20px;
      color: $black-base;
      font-weight: bold;
      background-color: $white-base;
      padding: 15px;
      margin-top: 10px;
    }
  }
  .select-different-plan {
    th {
      color: $black-base;
      font-weight: bold;
      background-color: $white-base;
      padding: 15px;
      text-transform: uppercase;
      font-family: "'LatoBold'";
      text-transform: uppercase;
    }
  }
  .card {
    border: 3px solid $lightgrey-base;
    margin-top: 10px;
    padding: 20px;
  }
  &__settings {
    width: 90%;
    &--title {
      display: flex;
      color: $black-base;
      font-size: 20px;
      font-family: "'LatoBold'";
    }

    &--table {
      border: 1px solid $darkgrey-lighter;
      table {
        table-layout: fixed;
        width: 100%;

        fieldset.switch {
          padding-top: 10px;
          padding-left: 10%;
          margin-bottom: 0.2rem !important;
        }
        th {
          background: $darkgrey-lighter;
          color: $black-base;
          font-family: 'LatoRegular';
          font-size: 13px;
          letter-spacing: 1px;
          width: inherit;
          width: 50%;
        }
        th + th {
          width: auto;
        }
        td {
          font: inherit;
        }
        thead {
          border-bottom: unset;
        }
        tr.select-different-plan {
          tr {
            display: block;
          }
        }
        .select-plan {
          tr {
            display: block;
          }
        }
      }
    }
    .actions {
      display: flex;
      gap: 20px;
    }
  }

  a.descriptionLink {
    text-decoration: underline;
  }
  .card-section {
    margin: 10px;
  }
  .title {
    font-size: 20px;
    color: $black-base;
    font-weight: bold;
  }

  .sub-title {
    font-size: 30px;
    color: $black-base;
    font-weight: bold;
  }
  .sub-title-3 {
    font-size: 20px;
    color: $black-base;
  }

  .plan-title {
    font-family: "'LatoBold'";
    text-transform: uppercase;
  }

  .plan-selection-radio {
    display: flex;
    justify-content: left;
    float: left;
  }

  .plan-selection-description {
    display: flex;
    justify-content: left;
    float: left;
    margin-top: 10px;
    align-items: center;
  }

  .button-block {
    text-align: center;
    margin-top: 40px;

    button {
      margin: 20px;
      align-self: center;
      width: 40%;
      padding: 1rem;
      margin-bottom: 2rem;
      border: 3px solid $red-base;
      font-size: 14px;
      text-transform: uppercase;
    }

    .warn-disabled {
      border: 1px solid $darkgrey-light;
    }

    a {
      width: 40%;
      margin: 20px;
    }
  }
}

.program-management-confirm-modal {
  height: auto;
  border-top: 10px solid $red-base;
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    flex-direction: column;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $black-base;
      font-size: 30px;
      font-family: "'LatoBold'";
      line-height: 38px;
      padding-bottom: 10px;
      font-weight: bold;
    }
    .details {
      display: flex;
      justify-content: left;
      align-items: left;
      text-align: left;
      color: $black-base;
      font-family: "'LatoBold'";
      line-height: 1.3;
      font-size: 16px;
    }
    .button-block {
      margin-top: 40px;

      .cancel {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: $darkgrey-base;
        letter-spacing: 1.4px;
        cursor: pointer;
      }
      button {
        width: 100%;
      }
    }
  }
}

input[type='radio'] {
  border: 10px dotted $black-base !important;
  height: 20px !important;
  width: 20px !important;
  accent-color: $black-base;
  margin: 10px;
}
