.store {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;

  h1 {
    font-family: "'LatoBold'";
    color: $black-base;
    font-size: 30px;
    line-height: 38px;
  }
}
