.start-application {
  &__content {
    width: 70%;
    margin-top: 2rem;
  }

  &__disclosure {
    &--header {
      font-family: "'LatoBold'";
      font-size: 20px;
      line-height: 26px;
    }

    span {
      color: $red-base;
    }

    &--psa {
      margin-top: 1rem;
      font-size: 16px;
      font-family: 'LatoItalic';
    }

    &--print {
      margin-top: 3rem;
      width: 100%;

      button {
        font-family: 'LatoBlack';
        letter-spacing: 1.4px;
        width: 60%;
        padding: 17px 60px;
      }
    }

    &--content {
      line-height: 26px;
      font-family: 'Lato';
      font-size: 16px;
      color: $black-base;

      a {
        font-family: 'Lato';
        font-size: 14px;
      }
    }
  }

  &__form {
    .checkbox-container {
      display: flex;
      font-size: 16px;
      font-family: 'LatoRegular';
      line-height: 24px;
      color: $black-base;

      .checkbox {
        width: unset;
      }

      fieldset {
        margin-bottom: 16px !important;
      }
    }

    &--actions {
      display: flex;
      width: 100%;

      .submit {
        width: 60%;
        margin-right: 5px;
      }

      .cancel {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: $darkgrey-base;
        cursor: pointer;
      }

      button {
        width: 100%;
      }
    }
  }

  &__push_app {
    margin-top: 0;
    width: 100%;
  }

  .separator {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $darkgrey-lighter;
    font-family: "'LatoBold'";
    margin-top: 40px;
    margin-left: -18px;
  }

  .separator:after,
  .separator:before {
    content: '';
    display: block;
    background: $darkgrey-lighter;
    width: 100%;
    height: 3px;
    margin: 0 18px;
  }

  .push-app {
    margin-top: -2rem;

    &__form {
      margin-top: 25px !important;

      form {
        width: 100%;
      }

      fieldset {
        margin-bottom: 16px !important;
      }

      button {
        font-family: 'LatoBlack';
        letter-spacing: 1.4px;
        width: 60%;
        padding: 17px 60px;
        margin-top: 20px;
      }
    }
  }
}
