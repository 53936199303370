.page-container {
  padding: 0 10%;
  margin: 5% 0;
  text-align: justify;
}

.page-title {
  text-align: center;
  padding-bottom: 25px;
}

.paragraph-basic {
  margin-top: 2%;
  a {
    font-size: 16px;
    font-weight: 400;
  }
}

.section-title {
  margin-top: 4%;
}

.footer-info {
  margin-top: 4%;
  padding: 2%;
  width: 100%;
  border: 1px solid $black-base;
  ol > li {
    list-style: decimal;
    margin-left: 2%;
    font-size: 16px;
  }
}

.paragraph-list {
  margin-top: 0.5%;
  li {
    font-size: 16px;
  }
}

.terms-list {
  padding: 0.5% 2%;
  > li {
    margin: 1%;
    padding-left: 0.5%;
  }
}

.prequal-table {
  margin-top: 2%;
  tbody > tr {
    > td {
      font-size: 16px;
      border: 1px solid rgba($black-base, 0.5);
    }
  }
}

.row-title {
  background: rgba($black-base, 0.2);
}

.terms-link {
  color: $blue-base;
  text-decoration: underline;
  font-size: 16px;
}

.contact {
  @extend .terms-link;
  text-decoration: none;
}

.fact-table-header {
  display: flex;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 2%;
  > .right-box {
    border-bottom: 2px solid $black-base;
  }
}

.fact-table-1 {
  border: 1px solid $black-base;
}

.fact-table-row {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $black-base;
}

.left-box {
  background: rgba($black-base, 0.6);
  width: 15%;
  display: flex;
  align-items: center;
  padding: 2% 0;
  h3 {
    margin-bottom: 0;
    padding-left: 5%;
    color: $white-base;
  }
}

.right-box {
  width: 85%;
  padding: 2%;
}

.fact-table-2 {
  margin-top: 2%;
  tr {
    height: 60px;
    border: 1px solid $black-base;
    th {
      font-size: 18px;
      background: rgba($black-base, 0.4);
      color: $white-base;
      border-right: 1px solid $black-base;
    }
    td {
      font-size: 16px;
      border-right: 1px solid $black-base;
    }
  }
}

.fact-table-3 {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  border: 1px solid $black-base;
  width: 100%;
  .table-3-header {
    border-bottom: 1px solid $black-base;
    padding: 1%;
    background: rgba($black-base, 0.5);
    h3 {
      margin: 0;
      color: $white-base;
    }
  }
  .table-3-body {
    .table-3-row {
      display: flex;
      width: 100%;
      border-bottom: 1px solid $black-base;
      .table-3-left {
        border-right: 1px solid $black-base;
        padding: 1%;
        width: 30%;
        display: flex;
        align-items: center;
      }
      .table-3-right {
        padding: 1%;
        width: 70%;
      }
    }
    .table-3-info {
      flex-direction: column;
      padding: 0 1%;
    }
  }
}

.fact-table-4 {
  display: flex;
  margin-top: 2%;
  border: 1px solid $black-base;
  .left-box {
    width: 30%;
    border-right: 1px solid $black-base;
  }
  .right-box {
    width: 70%;
  }
  h3 {
    font-size: 30px;
  }
}
