.practice {
  font-size: 30px;
  color: $black-base;
  margin-bottom: 30px;
  line-height: 1.3;
  font-family: "'LatoBold'";
  text-align: center;
}

.back-button {
  margin-left: 20px;
  vertical-align: bottom;
}

.practice-content {
  max-width: 750px;
  margin: 50px auto;
  .MuiInput-root {
    width: 100%;
  }
  .found-result {
    font-size: 18px;
    font-family: "'LatoBold'";
    color: $black-base;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .practice-result {
    min-height: 55vh;
  }

  .cannot-found {
    color: $black-base;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .cannot-found-content {
    color: $black-base;
    font-size: 18px;
  }

  .doctor-refer {
    color: $blue-base;
    font-size: 18px;
    font-style: italic;
    margin-bottom: 50px;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .practice-content {
    width: 85%;
  }
}
