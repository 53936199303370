.report-page {
  display: flex;
  align-items: center;
  flex-direction: column;

  color: $black-base;
  font-size: 16px;
  font-family: "'LatoBold'";
  margin-top: 30px;

  h1 {
    font-family: "'LatoBold'";
    color: $black-base;
  }

  form {
    width: unset;
  }

  label {
    font-family: inherit;
    font-size: inherit;
    margin-bottom: 0;
  }

  &__container {
    margin: 0 auto;
    width: 90%;
  }

  .report-form {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .radioInput {
      cursor: pointer;
    }
  }

  .date-selection-container {
    display: flex;
    align-items: baseline;
    gap: 16px;

    .date-current-month {
      white-space: nowrap;
    }

    .date-selection {
      display: flex;
      gap: 16px;
      width: 100%;

      .date-input-wrapper {
        font-family: 'LatoRegular';
      }

      fieldset {
        flex: 1;
        margin: unset !important;

        input[type='text'] {
          font-family: 'LatoRegular';
          font-size: 1rem;
          padding: unset !important;
          padding-bottom: 8px !important;
        }
      }
    }

    .RadioGroup {
      display: flex;
      gap: 16px;
      margin-bottom: 0 !important;
      width: unset;

      fieldset {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 8px;
        margin-bottom: 0 !important;
        width: unset;

        label {
          color: $black-base;
          font-family: 'LatoRegular';
          font-size: 16px;
          font-weight: normal;
          margin: unset;
          opacity: 0.75;
          white-space: nowrap;
        }
        input[type='radio'] {
          -webkit-appearance: radio;
          appearance: none;
          background-color: $white-base;
          margin: 0;
          color: $black-base;
          width: 22px;
          height: 22px;
          border: 2.5px solid $black-base !important;
          padding: unset !important;
          border-radius: 50%;
          display: grid;
          place-content: center;
        }

        input[type='radio']::before {
          content: '';
          width: 14px;
          height: 14px;
          border-radius: 50%;
          transform: scale(0);
          background-color: $black-base;
          transition: 120ms transform ease-in-out;
          background-color: CanvasText;
        }

        input[type='radio']:checked::before {
          transform: scale(1);
        }
      }
    }
  }

  .report-criteria-container {
    display: flex;
    align-items: baseline;
    gap: 32px;
    justify-content: space-between;

    .select-container {
      display: flex;
      align-items: baseline;
      flex: 2;

      font-family: 'LatoRegular';
      width: 100%;

      label {
        flex-shrink: 0;
        margin-right: 8px;
      }

      .select-input {
        width: 100%;

        .custom-select {
          &__control,
          &__option {
            cursor: pointer;
          }

          &__control {
            margin-top: 0 !important;
          }
        }

        .error-message {
          font-family: "'LatoBold'";
        }
      }
    }

    .select-container:first-of-type {
      flex: 1;
    }
  }

  fieldset {
    label {
      color: $black-base;
    }
  }
  .button button {
    align-self: center;
    border: 3px solid $red-base;
    font-size: 14px;
    text-transform: uppercase;
  }

  li {
    list-style-type: decimal;
    font-size: 16px;
    font-family: "'LatoBold'";
    line-height: 30px;
  }
  a {
    font-size: 16px;
    color: $black-base;
  }
  ol {
    margin-left: 40px;
  }
}
