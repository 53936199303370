.verify-store-information-title {
  fieldset label {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .react-select-container {
    position: relative;
    box-sizing: border-box;
    margin-top: 20px;
    text-align: left;
    margin-bottom: 44px;
  }

  padding-top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 54%;
  text-align: center;
  .terms-container {
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    border-top: 2px solid $grey-base;
    .required-field-error {
      position: absolute;
      margin-top: 120px;
      color: $red-base;
      max-width: 35%;
    }
    .terms-subtitle {
      padding-bottom: 32px;
    }
    h2 {
      font-family: "'LatoBold'";
      font-size: 28px;
      color: $black-base;
    }
    .term-input {
      display: flex;
      margin-bottom: 12px;
      label {
        display: flex;
        flex-direction: row;
        .terms-html-content {
          a,
          span {
            color: $blue-base;
          }
          span {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
    .authorized-signer-description {
      margin: 32px 0;
      font-family: 'LatoRegular';
      color: $black-base;
      font-size: 20px;
      text-align: left;
      border: 2px solid $grey-base;
      overflow-y: scroll;
      height: 400px;
      padding: 28px;
      li,
      p {
        display: inline-block;
        font-size: 20px;
        font-family: 'LatoRegular';
        color: $black-base;
        margin-bottom: 24px;
        span {
          margin-left: 12px;
        }
      }
      ol {
        counter-reset: list;
      }
      ol > li {
        list-style: none;
      }
      ol > li:before {
        content: '(' counter(list, lower-roman) ') ';
        counter-increment: list;
      }
    }
    .name-input-container {
      margin-top: 16px;
    }
    .esign-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 48px 0 0 0;
      min-width: 50%;
      width: fit-content;
      max-width: 100%;
      .sign-field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 94%;
        .signature-container {
          border: none;
          border-bottom: 1px solid $black-base;
          font-style: italic;
          height: 48px;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
          &.pointer {
            cursor: pointer;
          }
          .edit-signature-icon {
            margin-bottom: 10px;
          }
          .signature-text {
            font-style: italic;
          }
          .placeholder-text {
            opacity: 0.5;
          }
        }
        .submit-button-container {
          margin-top: 32px;
        }
        .sign-label {
          padding: 0;
          width: fit-content;
        }
      }
      .buttons-container {
        button {
          padding: 18px;
          margin-right: 12px;
        }
      }
    }
  }
  label {
    padding: 32px 0 0 0;
    margin-top: 12px;
    width: 100%;
    text-align: left;
    &.invalid {
      color: $red-base;
    }
    &.checkbox-label {
      padding: 0;
    }
  }
  input {
    border: none;
    border-bottom: 1px solid $black-base;
    padding: 10px 0 10px 0;
    font-size: 18px;
    font-family: 'LatoRegular';
    &.invalid {
      border-bottom: 1px solid $red-base;
      background-color: $red-light;
    }
  }
  .verify-store-information-subtitle {
    padding-top: 32px;
  }
  .form-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-height: 130px;
    .loading-bank-spinner {
      align-self: flex-start;
      margin-top: 70px;
      margin-left: 10px;
    }
    .store-number-label {
      margin-bottom: 24px;
    }
  }
  .input-container-double {
    min-width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 3% 0 0;
  }
  .label-and-tooltip-container {
    display: flex;
    align-items: flex-end;
    .check-tooltip {
      position: relative;

      .tooltip-icon {
        width: 24px;
        margin-left: 8px;
      }
      .tooltip-container {
        position: absolute;
        bottom: 50px;
        left: -251px;
        display: none;
        width: 550px;
        z-index: 3;
        box-shadow: 0px 3px 5px 4px $lightgrey-base;
        padding: 10px;
        background-color: $white-base;
        &.visible {
          display: block;
        }
      }
    }
  }
  .input-container-single {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .store-information-form-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid $lightgrey-base;
    border-radius: 10px;
    margin-top: 32px;
    form {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .save-or-cancel-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 60px 0 32px 0;
      }
      .readonly-field {
        background-color: $lightgrey-light;
        border: none;
        border-bottom: $lightgrey-base;
        font-family: 'LatoRegular';
        color: $black-base;
        font-size: 16px;
      }
    }
  }
  .verify-store-information {
    width: 100%;
    padding-top: 48px;
    .store-information-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-top: 2px solid $grey-base;
      padding: 0;
      .readonly-note {
        color: $darkgrey-base;
      }
      .store-information-display {
        border: 2px solid $lightgrey-base;
        border-radius: 10px;
        padding: 0px 20px;
        width: 100%;
        margin-top: 0;
        .icon {
          width: 28px;
          margin-right: 8px;
          &:hover {
            cursor: pointer;
          }
        }
        .edit-information-button-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 24px 0 36px 0;
          .edit-information-button {
            font-size: 18px;
          }
          .edit-icon {
            color: $black-base;
          }
        }

        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 6px 0 6px 0;
          margin-bottom: 0px;
          padding: 20px 20px 20px 20px;
          .label-and-tooltip-container {
            display: flex;
            .check-tooltip {
              position: relative;
              .tooltip-icon {
                width: 24px;
                margin-left: 8px;
                margin-bottom: 8px;
              }
              .tooltip-container {
                position: absolute;
                bottom: 50px;
                left: -251px;
                display: none;
                width: 550px;
                z-index: 3;
                box-shadow: 0px 3px 5px 4px $lightgrey-base;
                padding: 10px;
                background-color: $white-base;
                &.visible {
                  display: block;
                }
              }
            }
          }
        }
        .blueChange {
          background-color: $lightblue-base;
        }
        h3 {
          font-size: 24px;
          color: $black-base;
        }
        .title {
          font-family: "'LatoBold'";
        }
        .value {
          font-family: 'LatoRegular';
        }
      }
      .store-information-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        vertical-align: middle;
        padding: 24px 0;
        cursor: pointer;
        .store-and-status {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          h3 {
            transform: translateY(25%);
          }
          .icon {
            width: 40px;
            height: 40px;
            min-width: 40px;
            margin-right: 16px;
            &.empty-circle {
              border-radius: 20px;
              border: 2px solid $grey-base;
            }
          }
        }
        .arrow-icon {
          font-size: 40px;
          color: $black-base;
        }
      }

      .store-information-review-info {
        color: $darkgrey-base;
        text-align: center;
        width: 80%;
        padding: 24px;
      }

      &.is-single-location {
        .store-information-header {
          display: none;
        }
        border-top: none;
      }

      &.is-multi-location {
        .store-information-header {
          display: flex;
        }
      }

      .complete-or-skip {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 24px 0 0px 0;
        width: 100%;
        margin-bottom: 24px;
        button {
          margin-right: 12px;
        }
        .mark-complete,
        .skip {
          padding: 24px;
          font-size: 16px;
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  .verify-store-information-title {
    width: 70%;
  }
  .store-information-display {
    .tooltip-icon {
      width: 18px !important;
      margin-left: 4px !important;
      margin-top: 4px !important;
    }
    .tooltip-container {
      width: 390px !important;
      left: -195px !important;
    }
  }
  .input-container-double {
    .tooltip-icon {
      width: 18px !important;
      margin-left: 4px !important;
      margin-bottom: 4px !important;
    }
    .tooltip-container {
      width: 390px !important;
      left: -195px !important;
    }
  }
}
@media (max-width: 768px) {
  .check-tooltip {
    display: none !important;
  }
  .verify-store-information-title {
    width: 85%;
    .verify-store-information {
      .store-information-container {
        .complete-or-skip {
          .mark-complete,
          .skip {
            padding: 12px 0 12px 0;
            font-size: 12px;
          }
        }
        .store-information-display {
          .edit-information-button-container {
            .edit-information-button {
              font-size: 14px;
            }
            .edit-icon {
              font-size: 18px;
            }
          }
          padding: 24px;
          h3 {
            font-size: 16px;
          }
          .row {
            padding: 0;
          }
        }
      }
    }
    .store-information-form-container {
      form {
        label,
        input,
        p {
          font-size: 14px;
        }
        span {
          margin-top: 110px;
          font-size: 12px;
        }
        .react-select-container {
          font-size: 14px;
          padding: 0;
          margin-top: 0;
        }
      }
    }
  }
}
