.card {
  border: 3px solid $lightgrey-base;
  margin: 20px 0;
  padding: 30px;
}

.card-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  .info-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    &.last-line-item {
      margin-bottom: 4px;
      border-bottom: 1px solid $lightgrey-base;
    }

    svg {
      color: $black-light;
    }
  }
}

.main-card-content {
  min-width: 60%;
}

.account-card-btns {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  justify-content: flex-end;
}

.card-title {
  font-size: 24px;
  color: $black-base;
  font-family: "'LatoBold'";
}

.card-area {
  color: $darkgrey-base;
  font-size: 16px;
  font-family: 'LatoRegular';

  .icon-button {
    padding: 6px 12px !important;
  }
}

.card-main {
  width: 100%;
  flex-wrap: wrap;

  &__inline {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 575.98px) {
  .card {
    .card-content {
      flex-direction: column;
    }

    &__button {
      margin-top: 25px;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}

.account-actions {
  position: absolute;
  right: 30px;

  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  gap: 10px;
  justify-content: space-between;

  button {
    font-family: 'LatoRegular';
    letter-spacing: 1.4px;
    max-width: 210px;
    text-transform: uppercase;
  }
}

.card-half {
  width: 50%;
  float: left;
}

@media (min-width: 768px) {
  .card-half__right {
    padding-left: 40px;
  }
}
