.users {
  margin: 0 auto;

  &__table {
    width: 85%;
  }

  table {
    table-layout: auto !important;
    th {
      width: inherit !important;
    }
  }
  .login {
    a {
      text-decoration: none;
    }
  }
  &__add-user {
    display: flex;
    margin-bottom: 2rem;

    button {
      align-self: center;
      width: 50%;
      border: 3px solid $red-base !important;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
  .start-application__form {
    .activity-filter,
    .transaction-filter {
      justify-content: space-between;
      padding: 10px 10px;
      width: 100%;
      &--item {
        width: 35%;
      }
      .search {
        padding-top: 20px;
        width: 70%;
      }
      .publish {
        display: flex;
        padding-top: 30px;
        gap: 20px;
        flex-direction: row-reverse;
      }
    }
  }
}

.users-add {
  .forms-container {
    width: 100%;
    button {
      padding: 17px 30px;
    }
    .checkbox {
      width: 100% !important;
    }
  }

  .radio-group {
    &__share {
      .RadioGroup {
        fieldset:first-of-type {
          margin-top: 20px !important;
        }
      }
    }
  }

  .CheckboxGroup {
    margin-bottom: 1rem !important;
    fieldset:first-of-type {
      margin-top: 20px !important;
    }
    fieldset {
      justify-content: flex-start;
      flex-direction: row-reverse;
      width: 100% !important;
      padding-top: unset !important;
      margin-bottom: 20px !important;

      label {
        color: $black-base;
        font-family: 'LatoRegular';
        font-size: 16px;
        font-weight: normal;
        opacity: 0.75;
      }
    }
  }
}
select option:disabled {
  color: $black-light !important;
}
