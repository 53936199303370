.doc-upload-container {
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  max-width: 650px;
  text-align: center;
  margin: 0 auto;
  h1 {
    font-family: "'LatoBold'";
    color: $black-base;
    font-size: clamp(28px, 3.4vw, 42px);
  }
  h2 {
    font-family: 'LatoRegular';
    font-size: clamp(24px, 3vw, 30px);
    color: $black-base;
  }
  p {
    font-family: 'LatoRegular';
    font-size: 18px;
    color: $black-base;
    &.sub-text {
      color: $darkgrey-base;
    }
  }
  a {
    font-family: 'LatoRegular';
    font-size: 18px;
    color: $blue-base;
    text-decoration: underline;
  }

  .file-box {
    width: 100%;
  }
  .no-file-err .dropzone {
    border: 2px dashed $grey-base;
  }

  .has-file-err .dropzone {
    border: 2px dashed $red-base !important;
  }
  .no-file-err .file-err-msg {
    color: $black-base;
  }
  .has-file-err .file-err-msg {
    color: $red-base;
  }

  .location-container {
    border: 2px solid $lightgrey-base;
    border-radius: 10px;
    padding: 32px;
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .dropzone {
      width: 100%;
      border: 2px dashed $grey-base;
      border-radius: 10px;
      padding: 28px;
      margin: 8px 0 16px 0;
      cursor: pointer;
      &.accepted {
        border: 2px dashed $blue-base;
        background-color: rgba($blue-base, 0.08);
      }
      &.rejected {
        border: 2px dashed $red-base;
        background-color: rgba($red-base, 0.08);
      }
      .hidden {
        display: none;
      }
      label {
        text-align: center;
        font-family: 'LatoRegular';
        font-size: 18px;
        color: $blue-base;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .uploaded-file-container {
      width: 100%;
      border: 2px solid $lightgrey-base;
      border-radius: 10px;
      margin: 8px 0 16px 0;
      display: flex;
      flex-direction: column;
      align-items: space-between;
      overflow: hidden;

      .file-name-and-close {
        width: 100%;
        padding: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        button {
          border: none;
          background-color: transparent;
          cursor: pointer;
        }
        .close-button {
          width: 24px;
        }
      }
      .loading-bar {
        width: 100%;
        height: 4px;
        background-color: transparent;
        animation-name: progress-bar;
        animation-duration: 1s;
      }
      @keyframes progress-bar {
        0% {
          width: 0%;
          background-color: $blue-base;
        }
        90% {
          width: 100%;
          background-color: $blue-base;
        }
        100% {
          background-color: transparent;
        }
      }
    }
    .submitted-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
      img {
        width: 36px;
        margin-right: 8px;
      }
    }
  }
  .submission-successful {
    h1,
    p {
      margin: 32px 0 32px 0;
    }
    .success-icon,
    .document-icon {
      width: 90px;
    }
  }
  .return-to-dash {
    margin-top: 24px;
  }
}

@media (max-width: 768px) {
  .doc-upload-container {
    width: 80%;
  }
}
