.contract-success {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;

  .checkbox {
    padding-top: 12px;
    width: 6%;
  }
  .header-message {
    text-align: center;
  }

  h2 {
    font-size: clamp(24px, 3.2vw, 32px);
    color: $black-base;
    font-family: 'LatoRegular';
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 40px;
  }

  p {
    font-size: clamp(18px, 2.2vw, 22px);
    color: $black-base;
    font-family: 'LatoLight';
    line-height: 160%;
    margin: 20px 40px;
  }

  input[type='checkbox'] {
    border: 1px solid $black-base;

    &:checked {
      &:after {
        left: 1px;
        top: 0px;
      }
    }
  }

  @media (max-width: 575.98px) {
    p {
      margin: 10px 10px 0;
    }
    .large-icon-center {
      width: 50px;
    }
  }
}

.large-icon-center {
  display: inline-block;
  padding-bottom: 15px;
  margin-right: auto;
  width: 70px;
}

.contract-success-header {
  background-color: $lightgrey-light;
  margin-top: 55px;
  margin-bottom: 40px;
  font-size: clamp(18px, 1.8vw, 20px);
  font-weight: bold;
  color: $black-base;
  padding: 15px 15px 15px 40px;
  font-family: 'LatoRegular';
  border-radius: 5px;
}
