.date-picker-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .react-datepicker-wrapper {
    width: 100%;
    input {
      border: unset;
      padding: 1em 0;
      border-bottom: 1px solid $black-base;
      &::placeholder {
        font-style: normal !important;
      }
    }
  }
  span.error {
    color: $red-base;
    padding: 2px 0;
  }
  input.invalid {
    border-bottom: 1px solid #bf2536;
    background-color: $red-light;
  }
}
/*
The following CSS rule is because we are using the react-datepicker library and also the lp-component "DateInput" component, which uses the react-datepicker library under the hood.
This can be removed once we remove the lp-component "DateInput" component from use.
*/
div:not(.day-picker-container) {
  .react-datepicker-wrapper {
    display: block;
  }
  .react-datepicker__tether-element-attached-top .react-datepicker__triangle,
  .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
  }
  .react-datepicker__tether-element-attached-top .react-datepicker__triangle,
  .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__tether-element-attached-top
    .react-datepicker__triangle::before,
  .react-datepicker__tether-element-attached-bottom
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
  }
  .react-datepicker__tether-element-attached-top
    .react-datepicker__triangle::before,
  .react-datepicker__tether-element-attached-bottom
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before {
    content: '';
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: $darkgrey-light;
  }

  .react-datepicker__tether-element-attached-top .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
  }
  .react-datepicker__tether-element-attached-top .react-datepicker__triangle,
  .react-datepicker__tether-element-attached-top
    .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: $grey-light;
  }
  .react-datepicker__tether-element-attached-top
    .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: $darkgrey-light;
  }

  .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
  }
  .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__tether-element-attached-bottom
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: $white-base;
  }
  .react-datepicker__tether-element-attached-bottom
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: $darkgrey-light;
  }

  .react-datepicker {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: $white-base;
    color: $black-base;
    border: 1px solid $darkgrey-light;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
  }

  .react-datepicker__triangle {
    position: absolute;
    left: 50px;
  }

  .react-datepicker__tether-element-attached-bottom.react-datepicker__tether-element {
    margin-top: -20px;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: $grey-light;
    border-bottom: 1px solid $darkgrey-light;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
  }

  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__year-dropdown-container--scroll,
  .react-datepicker__month-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
  }

  .react-datepicker__current-month {
    margin-top: 0;
    color: $black-base;
    font-weight: bold;
    font-size: 0.944rem;
  }

  .react-datepicker__navigation {
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 10px;
    height: 10px;
    overflow: hidden;

    border: 0.45rem solid transparent;
    z-index: 1;
  }
  .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: $grey-base;
  }
  .react-datepicker__navigation--previous:hover {
    border-right-color: $darkgrey-light;
  }
  .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: $grey-base;
  }
  .react-datepicker__navigation--next:hover {
    border-left-color: $darkgrey-light;
  }
  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: $grey-base;
  }
  .react-datepicker__navigation--years-previous:hover {
    border-top-color: $darkgrey-light;
  }
  .react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: $grey-base;
  }
  .react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: $darkgrey-light;
  }

  .react-datepicker__month-container {
    display: inline;
    float: left;
  }

  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
  }

  .react-datepicker__week-number {
    color: $grey-base;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    color: $black-base;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }

  .react-datepicker__day {
    cursor: pointer;
  }
  .react-datepicker__day:hover {
    border-radius: 0.3rem;
    background-color: $grey-light;
  }
  .react-datepicker__day--today {
    font-weight: bold;
  }
  .react-datepicker__day--highlighted {
    border-radius: 0.3rem;
    background-color: $green-base;
    color: $white-base;
  }
  .react-datepicker__day--highlighted:hover {
    background-color: $green-base;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    border-radius: 0.3rem;
    background-color: $blue-base;
    color: $white-base;
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover {
    background-color: $blue-base;
  }
  .react-datepicker__day--keyboard-selected {
    border-radius: 0.3rem;
    background-color: $blue-base;
    color: $white-base;
  }
  .react-datepicker__day--keyboard-selected:hover {
    background-color: $blue-base;
  }
  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range
    ) {
    background-color: rgba($blue-base, 0.5);
  }
  .react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(
      .react-datepicker__day--in-selecting-range
    ) {
    background-color: $grey-light;
    color: $black-base;
  }
  .react-datepicker__day--disabled {
    cursor: default;
    color: $grey-base;
  }
  .react-datepicker__day--disabled:hover {
    background-color: transparent;
  }

  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
  }

  .react-datepicker__year-read-view,
  .react-datepicker__month-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
  }
  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover {
    cursor: pointer;
  }
  .react-datepicker__year-read-view:hover
    .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__year-read-view:hover
    .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-read-view:hover
    .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view:hover
    .react-datepicker__month-read-view--down-arrow {
    border-top-color: $darkgrey-light;
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow {
    border-top-color: $grey-base;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem;
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown {
    background-color: $grey-light;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid $darkgrey-light;
  }
  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover {
    cursor: pointer;
  }
  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
  }

  .react-datepicker__year-option,
  .react-datepicker__month-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover {
    background-color: $grey-base;
  }
  .react-datepicker__year-option:hover
    .react-datepicker__navigation--years-upcoming,
  .react-datepicker__month-option:hover
    .react-datepicker__navigation--years-upcoming {
    border-bottom-color: $darkgrey-light;
  }
  .react-datepicker__year-option:hover
    .react-datepicker__navigation--years-previous,
  .react-datepicker__month-option:hover
    .react-datepicker__navigation--years-previous {
    border-top-color: $darkgrey-light;
  }
  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected {
    position: absolute;
    left: 15px;
  }

  .react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 0;
    outline: 0;
    padding: 0;
    vertical-align: middle;
  }
  .react-datepicker__close-icon::after {
    background-color: $blue-base;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: $white-base;
    content: '\00d7';
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 50%;
  }

  .react-datepicker__today-button {
    background: $grey-light;
    border-top: 1px solid $darkgrey-light;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
  }

  .react-datepicker__tether-element {
    z-index: 2147483647;
  }

  .react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba($black-base, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
  }
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day {
    width: 3rem;
    line-height: 3rem;
  }
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day {
      width: 2rem;
      line-height: 2rem;
    }
  }
  .react-datepicker__portal .react-datepicker__current-month {
    font-size: 1.44rem;
  }
  .react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent;
  }
  .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: $grey-base;
  }
  .react-datepicker__portal .react-datepicker__navigation--previous:hover {
    border-right-color: $darkgrey-light;
  }
  .react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: $grey-base;
  }
  .react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: $darkgrey-light;
  }

  .react-datepicker-popper {
    z-index: 1;
  }
}
